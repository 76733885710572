import { FC } from 'react'
import { Popover } from 'antd'
import styles from './BulkActionsPopover.less'
import BulkActionsPopoverContent from './BulkActionsPopoverContent'

const BulkActionsPopover: FC = () => {
  return (
    <Popover
      open
      content={<BulkActionsPopoverContent />}
      overlayClassName={styles.popover}
      destroyTooltipOnHide
    />
  )
}

export default BulkActionsPopover
