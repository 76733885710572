export const PRE_AUTH_MERCHANT_IDS = [
  2501, // todd-snyder.myshopify.com
  24190, // TheUnitedStatesGoldBureau’s Company
  32296, // Albertsons’s Company
  34158, // Worldpay
  34166, // WorldpayTest
  34441, // Xiaomi MX’s Company
  34753, // WMT ODMX’s Company
]

// TODO FET-2131 - Remove when backend translations are in place
export const THUMBS_UP_REQUIRED_CUSTOMERS = [24461, 29631 /* WalmartMX */]
