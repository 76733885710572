import {
  getInvestigationAggregates,
  SEARCH_FIELD,
  SearchAggregationQuery,
  SearchAggregationResponse,
  SearchValue,
  SEARCH_OPERATOR,
  SEARCH_ORDER,
} from '@signifyd/http'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import { ColFilterKey } from 'pages/InvestigationSearchPage/containers/SearchResultsTable/ColumnFilterConfig'
import { useTableQueryParams } from '../queries/useTableQueryParams'

const FIELD_LIMIT = 20

export interface FieldValue {
  value: SearchValue
}

export interface FilterFields {
  fields: Array<FieldValue>
}

const orderFields = (
  aggregateFieldsResponse: Pick<SearchAggregationResponse, 'fields'>
): Array<FieldValue> => {
  const { fields } = aggregateFieldsResponse

  const accumulatedFieldRecords = Object.keys(fields).map((field) => {
    return fields[field].fieldCounts
  })

  const flattenedFields = accumulatedFieldRecords.flat(1)

  const fieldsInAlphabeticalOrder = [...flattenedFields].sort((a, b) => {
    return String(a.value).localeCompare(String(b.value))
  })

  return fieldsInAlphabeticalOrder
}

export const createAggregateSearchQuery = (
  searchKeys: Array<SEARCH_FIELD>
): Pick<SearchAggregationQuery, 'fields'> => {
  const fields = searchKeys.reduce((searchKeys, searchKey: SEARCH_FIELD) => {
    return {
      ...searchKeys,
      [searchKey]: {
        fieldName: searchKey,
        order: SEARCH_ORDER.DESCENDING,
        size: FIELD_LIMIT,
      },
    }
  }, {})

  return { fields }
}

const useGetAggregateFilters = (
  searchKeys: Array<SEARCH_FIELD>,
  columnKey: ColFilterKey
): UseQueryResult<FilterFields> => {
  const { t } = useTranslation()
  const {
    query: { normalizedPurchaseCreatedAt },
  } = useTableQueryParams()

  return useQuery(
    [`uniqueValues-${columnKey}`],
    async () => {
      const { fields } = createAggregateSearchQuery(searchKeys)

      const searchQuery: SearchAggregationQuery = {
        query: {
          and: [
            {
              field: {
                fieldName: SEARCH_FIELD.normalizedPurchaseCreatedAt,
                operator: SEARCH_OPERATOR.RANGE,
                values: [
                  normalizedPurchaseCreatedAt!.min!,
                  normalizedPurchaseCreatedAt!.max!,
                ],
              },
            },
          ],
        },
        filters: {},
        fields,
      }

      const { data: search } = await getInvestigationAggregates(searchQuery)

      return { fields: orderFields(search) }
    },
    {
      enabled:
        !!normalizedPurchaseCreatedAt?.min &&
        !!normalizedPurchaseCreatedAt?.max,

      onError: () => {
        message.error(t('dynamicFilters.error'))
      },
    }
  )
}

export default useGetAggregateFilters
