import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Space, Text, NoteTextArea } from '@signifyd/components'
import { PREDICTION_LIST_TYPE, PredictionList } from '@signifyd/http'
import { MAX_NOTE_LENGTH } from './CoreBulkActionsNotesModal'
import { useBulkActionsContext } from '../BulkActionsProvider'
import { DataSource } from '../types'
import styles from './BulkActionsAddToListNotesModalContent.less'

interface Props {
  selectedList: PredictionList
  dataSource?: DataSource
}

const BulkActionsAddToListNotesModalContent: FC<Props> = ({
  selectedList,
  dataSource,
}) => {
  const { t } = useTranslation()

  const {
    note,
    setNote,
    bulkActionType,
    selectedInvestigations,
    fileAttacher,
  } = useBulkActionsContext()

  const { name, type } = selectedList

  const listSource =
    type === PREDICTION_LIST_TYPE.EMAIL || type === PREDICTION_LIST_TYPE.ADDRESS
      ? `bulkActions.bulkAddToList.dataSourceFields.${dataSource}`
      : `bulkActions.bulkAddToList.addToListModal.listTypeStartCase.${type}`

  if (!bulkActionType) {
    return null
  }

  return (
    <>
      <Text data-test-id="bulkActionsNotesModalTitle">
        <Trans
          values={{
            count: selectedInvestigations.length,
            listType: t(listSource, {
              defaultValue: '',
            }),
            listName: name,
          }}
          components={{
            bold: <Text weight="semibold" />,
            lowercase: <Text className={styles.textLowercase} />,
          }}
        >{`bulkActions.notesModal.actionButtons.${bulkActionType}`}</Trans>
      </Text>
      <Space size={12} />

      <NoteTextArea
        note={note}
        maxLength={MAX_NOTE_LENGTH}
        fileAttacher={fileAttacher}
        onChange={(value) => setNote(value)}
        placeholder={t('bulkActions.notesModal.addNotesPlaceholder')}
        textAreaAnalyticsId="bulk-actions-notes-modal-text-area"
        attachFileButtonAnalyticsId="bulk-actions-attach-file-button"
      />
    </>
  )
}

export default BulkActionsAddToListNotesModalContent
