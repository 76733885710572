import {
  RecommendedDecisionDispositionTag,
  i18nInstance,
  CheckoutTag,
  ExternalLink,
  getDecisionCenterUrl,
  Text,
} from '@signifyd/components'
import {
  InvestigationInfo,
  INV_GUARANTEE_DISPOSITION,
  PhysicalAddress,
  SEARCH_FIELD,
  INV_SIMILARITY_FIELD,
  DECISION_MECHANISM,
} from '@signifyd/http'
import { formatCurrencyNumber } from '@signifyd/utils'
import { CheckSquareFilled } from '@ant-design/icons'
import { isFinite, startCase, toNumber } from 'lodash'
import { ColumnProps } from 'antd/lib/table'
import SearchTableHeader from 'pages/InvestigationSearchPage/components/SearchTableHeader'
import CustomerCell from './Cells/CustomerCell'
import DateTimeCell from './Cells/DateTimeCell'
import GuaranteeDispositionCell from './Cells/GuaranteeDispositionCell/GuaranteeDispositionCell'
import InvestigationIdCell from './Cells/InvestigationIdCell'
import SearchResultsCellLabel from './Cells/SearchResultsCellLabel'
import MultiItemPopover from './Cells/MultiItemPopover'
import ProductsCell from './Cells/ProductsCell'
import ReviewDispositionCell from './Cells/ReviewDispositionCell'
import TeamCell from './Cells/TeamCell'
import TruncatedTextView from './Cells/TruncatedTextView'
import styles from './SearchResultsTable.less'
import ChannelCell from './Cells/ChannelCell'
import MultiComponentPopover from './Cells/MultiComponentPopover'
import {
  renderSearchLink,
  getScoreClass,
  renderAddress,
  LinkedFieldWrapper,
} from './ColumnConfig.utils'
import {
  getConfirmationEmail,
  getConfirmationPhone,
  getDeliveryAddress,
} from './ColumnConfig.dataHelpers'
import {
  ReturnCheckpointNameCell,
  ReturnCheckpointActionCell,
} from './Cells/ReturnsCell'
import RecordLocatorCell from './Cells/RecordLocatorCell'
import LeadPassengerCell from './Cells/LeadPassengerCell'

interface ColumnConfig {
  isTraining: boolean
  isCustomer: boolean
  isRulesBuilderUser?: boolean
}

export type TableColumnProps = ColumnProps<InvestigationInfo>

export interface SearchTableColumnProps extends TableColumnProps {
  contextMatches: string
  columnName: string
}

type ColumnMap = Record<string, SearchTableColumnProps>

const getColumns = ({
  isTraining,
  isCustomer,
  isRulesBuilderUser,
}: ColumnConfig): Array<SearchTableColumnProps> => {
  const columns: ColumnMap = {
    investigationId: {
      columnName: 'caseId',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.caseId')}
          filterColumnKey="investigationId"
        />
      ),
      dataIndex: 'investigationId',
      contextMatches: 'investigationId',
      width: 140,
      fixed: 'left',
      render: (investigationId: string) => (
        <InvestigationIdCell caseId={investigationId} isCustomer={isCustomer} />
      ),
    },
    date: {
      columnName: 'date',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.date')}
          filterColumnKey="normalizedPurchaseCreatedAt"
        />
      ),
      key: 'normalizedPurchaseCreatedAt',
      contextMatches: 'createdAt',
      width: 150,
      fixed: 'left',
      render: (_text: null, rowData: InvestigationInfo) => (
        <DateTimeCell rowData={rowData} isCustomer={isCustomer} />
      ),
    },
    guaranteeDisposition: {
      columnName: 'guaranteeDisposition',
      title: (
        <SearchTableHeader
          title={i18nInstance.t(
            'investigationSearch.column.guaranteeDisposition'
          )}
          filterColumnKey="guaranteeDisposition"
          popoverOptions={{
            popoverSize: 'large',
          }}
        />
      ),
      width: 130,
      dataIndex: SEARCH_FIELD.guaranteeDisposition,
      contextMatches: 'guaranteeDisposition',
      fixed: 'left',
      render: (text: INV_GUARANTEE_DISPOSITION, rowData: InvestigationInfo) => (
        <GuaranteeDispositionCell
          text={text}
          isDecidedByMR={!!rowData.wasDecisionedByManualReview}
          isCustomer={isCustomer}
        />
      ),
    },
    chargeback: {
      columnName: 'chargeback',
      title: isTraining ? (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.chargeback')}
        />
      ) : (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.chargeback')}
          filterColumnKey="chargeback"
          popoverOptions={{
            popoverSize: 'large',
          }}
        />
      ),
      width: 160,
      contextMatches: 'chargeback',
      render: ({ claims }: InvestigationInfo) => (
        <div data-test-id="chargebackColumn">
          <MultiItemPopover
            items={claims.map(({ disposition, status = '' }) =>
              startCase(String(disposition ?? status).toLowerCase())
            )}
            title={i18nInstance.t('investigationSearch.column.chargeback')}
          />
        </div>
      ),
    },
    orderReviewFlag: {
      columnName: 'orderReviewFlag',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.orderReviewFlag')}
          filterColumnKey="investigationReviewDisposition"
        />
      ),
      width: 140,
      contextMatches: 'investigationReviewDisposition',
      dataIndex: SEARCH_FIELD.investigationReviewDisposition,
      className: `${styles.iconCellContent} ${styles.fullBgCell}`,
      render: (text) => <ReviewDispositionCell text={text} />,
    },
    cardHolderName: {
      columnName: 'cardHolderName',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.cardHolderName')}
          filterColumnKey="cardHolderName"
        />
      ),
      dataIndex: SEARCH_FIELD.cardHolderName,
      contextMatches: 'cardHolderName',
      width: 190,
      render: (text: string) =>
        renderSearchLink(text, SEARCH_FIELD.cardHolderName),
    },
    recipientFullName: {
      columnName: 'deliveryRecipient',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.deliveryRecipient')}
          filterColumnKey="recipientFullName"
        />
      ),
      key: 'recipientFullName',
      contextMatches: 'recipientFullName',
      width: 190,
      render: (_text: null, { recipients }: InvestigationInfo) =>
        renderSearchLink(
          recipients?.[0]?.fullName,
          SEARCH_FIELD.recipientFullName
        ),
    },
    userAccountEmail: {
      columnName: 'userAccountEmail',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.userAccountEmail')}
          filterColumnKey="userAccountEmail"
          popoverOptions={{ popoverSize: 'large' }}
        />
      ),
      dataIndex: SEARCH_FIELD.userAccountEmail,
      contextMatches: 'userAccountEmail',
      width: 250,
      render: (text: string) =>
        renderSearchLink(text, SEARCH_FIELD.userAccountEmail),
    },

    confirmationEmail: {
      columnName: 'confirmationEmail',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.confirmationEmail')}
          filterColumnKey="recipientConfirmationEmail"
          popoverOptions={{ popoverSize: 'large' }}
        />
      ),
      key: 'recipientConfirmationEmail',
      contextMatches: 'recipientConfirmationEmail',
      width: 250,
      className: styles.fullBgCell,
      render: (_text: null, rowData: InvestigationInfo) => (
        <LinkedFieldWrapper
          linkedFields={rowData.fieldMatches}
          similarityField={INV_SIMILARITY_FIELD.CONFIRMATION_EMAIL}
        >
          {renderSearchLink(
            getConfirmationEmail(rowData),
            SEARCH_FIELD.recipientConfirmationEmail
          )}
        </LinkedFieldWrapper>
      ),
    },

    billingAddress: {
      columnName: 'billingAddress',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.billingAddress')}
          filterColumnKey="billingAddressFullAddress"
          popoverOptions={{ popoverSize: 'large' }}
        />
      ),
      dataIndex: SEARCH_FIELD.billingAddress,
      contextMatches: 'billingAddress',
      className: styles.fullBgCell,
      width: 260,
      render: (
        billingAddress: PhysicalAddress | null,
        rowData: InvestigationInfo
      ) => {
        const address = billingAddress?.fullAddress

        return (
          <LinkedFieldWrapper
            linkedFields={rowData.fieldMatches}
            similarityField={INV_SIMILARITY_FIELD.BILLING_ADDRESS}
          >
            {renderAddress(
              SEARCH_FIELD.billingAddress,
              rowData,
              INV_SIMILARITY_FIELD.BILLING_ADDRESS,
              address
            )}
          </LinkedFieldWrapper>
        )
      },
    },

    deliveryAddress: {
      columnName: 'deliveryAddress',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.deliveryAddress')}
          filterColumnKey="recipientAddress"
          popoverOptions={{ popoverSize: 'large' }}
        />
      ),
      key: 'recipientAddress',
      contextMatches: 'recipientAddress',
      width: 260,
      className: styles.fullBgCell,
      render: (_text: null, rowData: InvestigationInfo) => {
        const address = getDeliveryAddress(rowData)?.fullAddress

        return (
          <LinkedFieldWrapper
            linkedFields={rowData.fieldMatches}
            similarityField={INV_SIMILARITY_FIELD.DELIVERY_ADDRESS}
          >
            {renderAddress(
              SEARCH_FIELD.recipientAddress,
              rowData,
              INV_SIMILARITY_FIELD.DELIVERY_ADDRESS,
              address
            )}
          </LinkedFieldWrapper>
        )
      },
    },

    userAccountPhone: {
      columnName: 'userAccountPhone',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.userAccountPhone')}
          filterColumnKey="userAccountPhone"
        />
      ),
      contextMatches: 'userAccountPhone',
      dataIndex: 'userAccountPhone',
      width: 180,
      render: (text) => renderSearchLink(text, SEARCH_FIELD.userAccountPhone),
    },

    ipGeolocation: {
      columnName: 'ipGeolocation',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.ipGeolocation')}
          filterColumnKey="ipGeolocation"
        />
      ),
      contextMatches: 'ipGeolocationFull',
      dataIndex: 'ipGeolocation',
      width: 210,
    },

    confirmationPhone: {
      columnName: 'confirmationPhone',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.confirmationPhone')}
          filterColumnKey="recipientConfirmationPhone"
        />
      ),
      key: 'confirmationPhone',
      contextMatches: 'recipientConfirmationPhone',
      className: styles.fullBgCell,
      width: 145,
      render: (_text: null, rowData: InvestigationInfo) => (
        <LinkedFieldWrapper
          linkedFields={rowData.fieldMatches}
          similarityField={INV_SIMILARITY_FIELD.CONFIRMATION_PHONE}
        >
          {renderSearchLink(
            getConfirmationPhone(rowData),
            SEARCH_FIELD.recipientConfirmationPhone
          )}
        </LinkedFieldWrapper>
      ),
    },

    team: {
      columnName: 'team',
      title: (
        <Text size="xs" className={styles.heading} weight="semibold">
          {i18nInstance.t('investigationSearch.column.team')}
        </Text>
      ),
      key: 'team',
      contextMatches: 'teamName',
      width: 180,
      render: TeamCell,
      className: styles.truncate,
    },

    customer: {
      columnName: 'customer',
      title: (
        <Text size="xs" className={styles.heading} weight="semibold">
          {i18nInstance.t('investigationSearch.column.customer')}
        </Text>
      ),
      key: 'customer',
      contextMatches: 'customerId',
      width: 180,
      className: styles.truncate,
      render: CustomerCell,
    },

    products: {
      columnName: 'products',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.products')}
          filterColumnKey="productName"
        />
      ),
      key: 'productName',
      contextMatches: 'items',
      width: 267,
      render: ProductsCell,
    },

    discountCode: {
      columnName: 'discountCode',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.discountCode')}
          filterColumnKey="discountCode"
        />
      ),
      width: 267,
      contextMatches: 'discountCode',
      render: ({ discountCodes }: InvestigationInfo) => (
        <MultiItemPopover
          items={discountCodes.map(({ code }) => code)}
          title={i18nInstance.t('investigationSearch.column.discountCode')}
        />
      ),
    },

    avsResponseCode: {
      columnName: 'avs',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.avs')}
          filterColumnKey="avsResponseCode"
        />
      ),
      width: 100,
      dataIndex: 'avsResponseCode',
      contextMatches: 'avsResponseCode',
      className: styles.fullBgCell,
      render(text: string) {
        return (
          text && (
            <div className={styles.fullBgWrapper}>
              <SearchResultsCellLabel
                type="code"
                cssClass={text}
                label={text}
              />
            </div>
          )
        )
      },
    },

    cvvResponseCode: {
      columnName: 'cvv',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.cvv')}
          filterColumnKey="cvvResponseCode"
        />
      ),
      width: 100,
      dataIndex: 'cvvResponseCode',
      contextMatches: 'cvvResponseCode',
      className: styles.fullBgCell,
      render(text: string) {
        return (
          text && (
            <div className={styles.fullBgWrapper}>
              <SearchResultsCellLabel
                type="code"
                cssClass={text}
                label={text}
              />
            </div>
          )
        )
      },
    },

    cardBin: {
      columnName: 'bin',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.bin')}
          filterColumnKey="cardBin"
        />
      ),
      width: 100,
      dataIndex: 'cardBin',
      contextMatches: 'cardBin',
      render: (text?: string) =>
        text && renderSearchLink(text, SEARCH_FIELD.cardBin),
    },

    binCountryCode: {
      columnName: 'binCountryCode',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.binCountryCode')}
          filterColumnKey="cardBinCountry"
        />
      ),
      width: 150,
      dataIndex: 'binCountryCode',
      contextMatches: 'cardBinCountryCode',
      render: (_text: null, { binData }: InvestigationInfo) =>
        binData?.country2_iso,
    },

    binCountry: {
      columnName: 'binCountry',
      title: (
        <Text size="xs" className={styles.heading} weight="semibold">
          {i18nInstance.t('investigationSearch.column.binCountry')}
        </Text>
      ),
      width: 150,
      dataIndex: 'binCountry',
      contextMatches: 'cardBinCountry',
      render: (_text: null, { binData }: InvestigationInfo) =>
        binData?.isocountry,
    },

    cardLastFourDigits: {
      columnName: 'cardLastFourDigits',
      title: (
        <SearchTableHeader
          title={i18nInstance.t(
            'investigationSearch.column.cardLastFourDigits'
          )}
          filterColumnKey="cardLastFourDigits"
        />
      ),
      width: 120,
      dataIndex: 'cardLastFourDigits',
      contextMatches: 'cardLastFourDigits',
      render: (_text: null, { cardLastFourDigits }: InvestigationInfo) =>
        cardLastFourDigits,
    },

    bindbBank: {
      columnName: 'bindbBank',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.bindbBank')}
          filterColumnKey="bindbBank"
        />
      ),
      width: 160,
      dataIndex: 'bindbBank',
      contextMatches: 'bindbBank',
      render: (_text: null, { binData }: InvestigationInfo) =>
        binData?.bank || '',
    },

    browserIpAddress: {
      columnName: 'ipAddress',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.ipAddress')}
          filterColumnKey="browserIpAddress"
        />
      ),
      dataIndex: 'browserIpAddress',
      contextMatches: 'browserIp',
      className: styles.fullBgCell,
      width: 140,
      render: (text: string | null, rowData: InvestigationInfo) =>
        text && (
          <LinkedFieldWrapper
            linkedFields={rowData.fieldMatches}
            similarityField={INV_SIMILARITY_FIELD.IP_ADDRESS}
          >
            {renderSearchLink(text, SEARCH_FIELD.browserIp)}
          </LinkedFieldWrapper>
        ),
    },

    threatMetrixDeviceId: {
      columnName: 'deviceId',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.deviceId')}
          filterColumnKey="threat_metrix_device_id"
        />
      ),
      dataIndex: 'threatMetrixDeviceId',
      contextMatches: 'deviceId',
      className: styles.fullBgCell,
      width: 130,
      render: (text: string | null, rowData: InvestigationInfo) => {
        if (!text) {
          return null
        }

        return (
          <LinkedFieldWrapper
            linkedFields={rowData.fieldMatches}
            similarityField={INV_SIMILARITY_FIELD.DEVICE_ID}
          >
            {renderSearchLink(
              text,
              SEARCH_FIELD.deviceId,
              text.length > 6
                ? i18nInstance.t('investigationSearch.column.deviceIdTrimmed', {
                    value: text.slice(-6),
                  })
                : text
            )}
          </LinkedFieldWrapper>
        )
      },
    },

    shippingMethod: {
      columnName: 'shippingMethod',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.shippingMethod')}
          filterColumnKey="shippingMethod"
        />
      ),
      key: 'shippingMethod',
      contextMatches: 'shippingMethod',
      width: 200,
      render: (_text: null, { shipments }: InvestigationInfo) => {
        if (!shipments) {
          return null
        }

        const shipmentMethods = shipments.map(
          ({ shippingMethod }) => shippingMethod
        )

        return (
          <MultiComponentPopover
            title={i18nInstance.t('investigationSearch.column.shippingMethod')}
            components={shipmentMethods}
          />
        )
      },
    },

    orderTotalAmount: {
      columnName: 'orderTotal',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.orderTotal')}
          filterColumnKey="orderTotalAmount"
        />
      ),
      key: 'amount',
      width: 150,
      contextMatches: 'orderTotalAmount',
      render: (_text: null, rowData: InvestigationInfo) => {
        return `${rowData.currency || ''} ${formatCurrencyNumber(
          toNumber(rowData.orderTotalAmount)
        )}`
      },
    },

    sellerId: {
      columnName: 'sellerId',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.sellerId')}
          filterColumnKey="sellerId"
        />
      ),
      width: 220,
      contextMatches: 'sellerId',
      render: ({ sellers }: InvestigationInfo) => (
        <MultiItemPopover
          items={sellers.map(({ sellerId }) => sellerId)}
          title={i18nInstance.t('investigationSearch.column.sellerId')}
          isLinked
          searchPrefix={SEARCH_FIELD.sellerId}
        />
      ),
    },
    parentEntity: {
      columnName: 'parentEntity',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.parentEntity')}
          filterColumnKey="parentEntity"
        />
      ),
      width: 220,
      contextMatches: 'sellerParentEntity',
      render: ({ sellers }: InvestigationInfo) => (
        <MultiItemPopover
          items={sellers.map(({ parentEntity }) => parentEntity)}
          title={i18nInstance.t('investigationSearch.column.parentEntity')}
          isLinked
          searchPrefix={SEARCH_FIELD.sellerParentEntity}
        />
      ),
    },
    sellerName: {
      columnName: 'sellerName',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.sellerName')}
          filterColumnKey="sellerName"
        />
      ),
      width: 220,
      contextMatches: 'sellerName',
      render: ({ sellers }: InvestigationInfo) => (
        <MultiItemPopover
          items={sellers.map(({ name }) => name)}
          title={i18nInstance.t('investigationSearch.column.sellerName')}
          isLinked
          searchPrefix={SEARCH_FIELD.sellerName}
        />
      ),
    },

    signifydScore: {
      columnName: 'score',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.score')}
          filterColumnKey="score"
        />
      ),
      dataIndex: 'signifydScore',
      width: 140,
      contextMatches: 'signifydScore',
      className: styles.fullBgCell,
      render: (score: number) => {
        if (isTraining || !isFinite(score)) {
          return null
        }

        return (
          <div className={styles.fullBgWrapper}>
            <SearchResultsCellLabel
              type="score"
              cssClass={getScoreClass(score)}
              label={Math.floor(score).toString()}
            />
          </div>
        )
      },
    },

    orderChannel: {
      columnName: 'channel',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.channel')}
          filterColumnKey="orderChannel"
        />
      ),
      dataIndex: 'orderChannel',
      contextMatches: 'orderChannel',
      className: styles.iconCellContent,
      width: 130,
      render: (_text: null, { orderChannel }: InvestigationInfo) => {
        if (!orderChannel) {
          return null
        }

        return <ChannelCell channel={orderChannel} />
      },
    },

    userAccountNumber: {
      columnName: 'userAccountNumber',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.userAccountNumber')}
          filterColumnKey="userAccountNumber"
        />
      ),
      dataIndex: 'userAccountNumber',
      contextMatches: 'userAccountNumber',
      width: 220,
    },

    orderExternalId: {
      columnName: 'orderId',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.orderId')}
          filterColumnKey="orderExternalId"
          popoverOptions={{
            popoverSize: 'medium',
            popoverPlacement: 'leftTop',
          }}
        />
      ),
      dataIndex: 'orderExternalId',
      contextMatches: 'orderId',
      width: 220,
    },

    transactionId: {
      columnName: 'transactionId',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.transactionId')}
          filterColumnKey="transactionId"
        />
      ),
      width: 220,
      dataIndex: 'transactionId',
      contextMatches: 'transactionId',
    },

    isTestInvestigation: {
      columnName: 'isTest',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.isTest')}
          filterColumnKey="isTestInvestigation"
          popoverOptions={{
            popoverSize: 'medium',
          }}
        />
      ),
      dataIndex: 'isTestInvestigation',
      className: styles.iconCellContent,
      contextMatches: 'isTestInvestigation',
      width: 160,
      render(isTest: boolean) {
        return (
          isTest && (
            <CheckSquareFilled
              style={{ fontSize: '24px' }}
              data-test-id="testIcon"
              title={i18nInstance.t(`icon.isTestInvestigation.${isTest}`)}
            />
          )
        )
      },
    },

    paymentGateway: {
      columnName: 'paymentGateway',
      title: (
        <Text size="xs" className={styles.heading} weight="semibold">
          {i18nInstance.t('investigationSearch.column.paymentGateway')}
        </Text>
      ),
      dataIndex: 'paymentGateway',
      contextMatches: 'paymentGateway',
      width: 100,
    },

    paymentMethod: {
      columnName: 'paymentMethod',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.paymentMethod')}
          filterColumnKey="paymentMethod"
        />
      ),
      width: 120,
      dataIndex: 'paymentMethod',
      contextMatches: 'paymentMethod',
    },

    authorizationGatewayStatus: {
      columnName: 'authorizationStatus',
      title: (
        <SearchTableHeader
          title={i18nInstance.t(
            'investigationSearch.column.authorizationStatus'
          )}
          filterColumnKey="authorizationGatewayStatus"
          popoverOptions={{
            popoverSize: 'large',
          }}
        />
      ),
      width: 197,
      contextMatches: 'authorizationGatewayStatus',
      dataIndex: 'authorizationGatewayStatus',
      render: (
        _text: null,
        { authorizationGatewayStatus }: InvestigationInfo
      ) => authorizationGatewayStatus,
    },

    authorizationFailureReason: {
      columnName: 'authorizationFailureReason',
      title: (
        <SearchTableHeader
          title={i18nInstance.t(
            'investigationSearch.column.authorizationFailureReason'
          )}
          filterColumnKey="authorizationFailureReason"
        />
      ),
      width: 210,
      contextMatches: 'authorizationFailureReason',
      dataIndex: 'authorizationFailureReason',
      render: (
        _text: null,
        { authorizationFailureReason }: InvestigationInfo
      ) => authorizationFailureReason,
    },
    guaranteeAutoDecisionReasonDescription: {
      columnName: 'guaranteeAutoDecisionReasonDescription',
      title: (
        <Text size="xs" className={styles.heading} weight="semibold">
          {i18nInstance.t(
            'investigationSearch.column.guaranteeAutoDecisionReasonDescription'
          )}
        </Text>
      ),
      dataIndex: 'guaranteeAutoDecisionDescription',
      contextMatches: 'guaranteeAutoDecisionDescription',
      width: 210,
      render: (
        _text: null,
        { guaranteeAutoDecisionReasonDescription }: InvestigationInfo
      ) => {
        return (
          <>
            {guaranteeAutoDecisionReasonDescription && (
              <TruncatedTextView
                text={guaranteeAutoDecisionReasonDescription}
              />
            )}
          </>
        )
      },
    },
    guaranteeAutoDecisionReason: {
      columnName: 'guaranteeAutoDecisionReason',
      title: (
        <SearchTableHeader
          title={i18nInstance.t(
            'investigationSearch.column.guaranteeAutoDecisionReason'
          )}
          filterColumnKey="guaranteeAutoDecisionReason"
        />
      ),
      dataIndex: 'guaranteeAutoDecisionReason',
      contextMatches: 'guaranteeAutoDecisionReason',
      width: 210,
      render: (
        _text: null,
        { guaranteeAutoDecisionReason }: InvestigationInfo
      ) => guaranteeAutoDecisionReason,
    },
    guaranteeReviewTimeoutDisposition: {
      columnName: 'guaranteeReviewTimeoutDisposition',
      title: (
        <SearchTableHeader
          title={i18nInstance.t(
            'investigationSearch.column.guaranteeReviewTimeoutDisposition'
          )}
          filterColumnKey="guaranteeReviewTimeoutDisposition"
        />
      ),
      dataIndex: 'guaranteeReviewTimeoutDisposition',
      contextMatches: 'guaranteeReviewTimeoutDisposition',
      width: 150,
    },
    recommendedDecisionDisposition: {
      columnName: 'recommendedDecisionDisposition',
      title: (
        <SearchTableHeader
          title={i18nInstance.t(
            'investigationSearch.column.recommendedDecisionDisposition'
          )}
          filterColumnKey="recommendedDecisionDisposition"
        />
      ),
      width: 190,
      contextMatches: 'recommendedDecisionDisposition',
      render: ({
        recommendedDecisionDisposition,
        decisionMechanism,
      }: InvestigationInfo) => {
        if (
          decisionMechanism === DECISION_MECHANISM.POLICY_ONLY_ACCEPT ||
          !recommendedDecisionDisposition
        ) {
          return null
        }

        if (!isCustomer) {
          return startCase(
            i18nInstance.t(
              `dynamicFilters.recommendedDecisionDisposition.${recommendedDecisionDisposition}`
            )
          )
        }

        return (
          <RecommendedDecisionDispositionTag
            recommendedDecisionDisposition={recommendedDecisionDisposition}
            showTooltip={false}
          />
        )
      },
    },
    provinceCode: {
      columnName: 'billingAddressProvinceCode',
      title: (
        <SearchTableHeader
          title={i18nInstance.t(
            'investigationSearch.column.billingAddressProvinceCode'
          )}
          filterColumnKey="billingAddressProvinceCode"
        />
      ),
      width: 130,
      contextMatches: 'billingAddressProvinceCode',
      dataIndex: 'billingAddressProvinceCode',
      render: (_text: null, { billingAddress }: InvestigationInfo) => {
        return billingAddress?.provinceCode
      },
    },

    teamId: {
      columnName: 'teamId',
      title: (
        <Text size="xs" className={styles.heading} weight="semibold">
          {i18nInstance.t('investigationSearch.column.teamId')}
        </Text>
      ),
      dataIndex: 'teamId',
      contextMatches: 'teamId',
      width: 100,
      className: styles.truncate,
    },
    checkoutPolicyName: {
      columnName: 'checkoutPolicyName',
      title: (
        <SearchTableHeader
          title={i18nInstance.t(
            'investigationSearch.column.checkoutPolicyName'
          )}
        />
      ),
      width: 250,
      contextMatches: 'checkoutPolicyName',
      render: (caseDetails: InvestigationInfo) => {
        const policyId =
          caseDetails.recommendedActionRuleId ??
          caseDetails.guaranteeRecommendedActionRuleId

        if (policyId) {
          return (
            <ExternalLink
              url={`${getDecisionCenterUrl()}/policies/summary/${policyId}`}
            >
              {caseDetails.checkoutPolicyName}
            </ExternalLink>
          )
        }

        return caseDetails.checkoutPolicyName
      },
    },
    checkoutPolicyAction: {
      columnName: 'checkoutPolicyAction',
      title: (
        <SearchTableHeader
          title={i18nInstance.t(
            'investigationSearch.column.checkoutPolicyAction'
          )}
          filterColumnKey="checkoutPolicyAction"
        />
      ),
      width: 250,
      contextMatches: 'checkoutPolicyAction',
      render: (caseDetails: InvestigationInfo) => {
        return (
          <CheckoutTag
            investigationInfoPolicyDetails={caseDetails}
            isRulesBuilderUser={isRulesBuilderUser}
          />
        )
      },
    },
    returnPolicyAction: {
      columnName: 'returnPolicyAction',
      title: (
        <SearchTableHeader
          title={i18nInstance.t(
            'investigationSearch.column.returnPolicyAction'
          )}
          filterColumnKey="returnDecisionCheckpointAction"
        />
      ),
      width: 250,
      contextMatches: 'returnPolicyAction',
      render: ({ returns }: InvestigationInfo) => (
        <ReturnCheckpointActionCell returns={returns} />
      ),
    },
    returnPolicyName: {
      columnName: 'returnPolicyName',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.returnPolicyName')}
        />
      ),
      width: 250,
      contextMatches: 'returnPolicyName',
      render: ({ returns }: InvestigationInfo) => (
        <ReturnCheckpointNameCell returns={returns} />
      ),
    },
    accountHolderTaxId: {
      columnName: 'accountHolderTaxId',
      title: (
        <SearchTableHeader
          title={i18nInstance.t(
            'investigationSearch.column.accountHolderTaxId'
          )}
          filterColumnKey="accountHolderTaxId"
        />
      ),
      width: 150,
      contextMatches: 'accountHolderTaxId',
      dataIndex: 'accountHolderTaxId',
    },

    processingPolicy: {
      columnName: 'processingPolicy',
      title: (
        <SearchTableHeader
          title={i18nInstance.t('investigationSearch.column.processingPolicy')}
          filterColumnKey="processingPolicy"
          popoverOptions={{
            popoverSize: 'large',
          }}
        />
      ),
      width: 150,
      contextMatches: 'processingPolicy',
      dataIndex: 'processingPolicy',
    },
    recordLocator: {
      columnName: 'recordLocator',
      title: (
        <Text size="xs" className={styles.heading} weight="semibold">
          {i18nInstance.t('investigationSearch.column.recordLocator')}
        </Text>
      ),
      width: 150,
      contextMatches: 'recordLocator',
      render: RecordLocatorCell,
    },
    leadPassenger: {
      columnName: 'leadPassenger',
      title: (
        <Text size="xs" className={styles.heading} weight="semibold">
          {i18nInstance.t('investigationSearch.column.leadPassenger')}
        </Text>
      ),
      width: 150,
      contextMatches: 'leadPassengerName',
      render: LeadPassengerCell,
    },
  } satisfies ColumnMap

  const customerColumns: Array<SearchTableColumnProps> = [
    columns.investigationId,
    columns.date,
    columns.guaranteeDisposition,
    columns.recommendedDecisionDisposition,
    columns.chargeback,
    columns.orderReviewFlag,
    columns.checkoutPolicyAction,
    columns.checkoutPolicyName,
    columns.returnPolicyAction,
    columns.returnPolicyName,
    columns.cardHolderName,
    columns.recordLocator,
    columns.leadPassenger,
    columns.recipientFullName,
    columns.userAccountEmail,
    columns.confirmationEmail,
    columns.billingAddress,
    columns.deliveryAddress,
    columns.ipGeolocation,
    columns.userAccountPhone,
    columns.confirmationPhone,
    columns.team,
    columns.customer,
    columns.products,
    columns.discountCode,
    columns.orderTotalAmount,
    columns.shippingMethod,
    columns.binCountryCode,
    columns.binCountry,
    columns.provinceCode,
    columns.avsResponseCode,
    columns.cvvResponseCode,
    columns.cardBin,
    columns.cardLastFourDigits,
    columns.bindbBank,
    columns.threatMetrixDeviceId,
    columns.browserIpAddress,
    columns.authorizationFailureReason,
    columns.authorizationGatewayStatus,
    columns.paymentGateway,
    columns.paymentMethod,
    columns.orderChannel,
    columns.teamId,
    columns.signifydScore,
    columns.orderExternalId,
    columns.transactionId,
    columns.userAccountNumber,
    columns.isTestInvestigation,
    columns.accountHolderTaxId,
    columns.sellerId,
    columns.parentEntity,
    columns.sellerName,
  ]

  const adminColumns: Array<SearchTableColumnProps> = [
    columns.investigationId,
    columns.date,
    columns.guaranteeDisposition,
    columns.chargeback,
    columns.orderReviewFlag,
    columns.checkoutPolicyAction,
    columns.checkoutPolicyName,
    columns.returnPolicyAction,
    columns.returnPolicyName,
    columns.cardHolderName,
    columns.recordLocator,
    columns.leadPassenger,
    columns.recipientFullName,
    columns.userAccountEmail,
    columns.confirmationEmail,
    columns.billingAddress,
    columns.deliveryAddress,
    columns.ipGeolocation,
    columns.userAccountPhone,
    columns.confirmationPhone,
    columns.team,
    columns.customer,
    columns.products,
    columns.discountCode,
    columns.orderTotalAmount,
    columns.shippingMethod,
    columns.binCountryCode,
    columns.binCountry,
    columns.provinceCode,
    columns.avsResponseCode,
    columns.cvvResponseCode,
    columns.cardBin,
    columns.cardLastFourDigits,
    columns.bindbBank,
    columns.threatMetrixDeviceId,
    columns.browserIpAddress,
    columns.authorizationFailureReason,
    columns.authorizationGatewayStatus,
    columns.paymentGateway,
    columns.paymentMethod,
    columns.orderChannel,
    columns.teamId,
    columns.signifydScore,
    columns.orderExternalId,
    columns.transactionId,
    columns.guaranteeAutoDecisionReasonDescription,
    columns.guaranteeAutoDecisionReason,
    columns.guaranteeReviewTimeoutDisposition,
    columns.recommendedDecisionDisposition,
    columns.userAccountNumber,
    columns.isTestInvestigation,
    columns.accountHolderTaxId,
    columns.sellerId,
    columns.parentEntity,
    columns.sellerName,
    columns.processingPolicy,
  ]

  return isCustomer ? customerColumns : adminColumns
}

export default getColumns
