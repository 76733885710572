import { FC } from 'react'
import { InvestigationInfo } from '@signifyd/http'
import { Text } from '@signifyd/components'
import { isTransportAirline } from '../airline.utils'

interface Props {
  products: InvestigationInfo['products']
}

const LeadPassengerCell: FC<Props> = ({ products }) => {
  if (!isTransportAirline(products)) {
    return null
  }

  const leadPassenger = products[0].travel?.passengers?.[0]

  if (!leadPassenger) {
    return null
  }

  const fullName = `${leadPassenger.passengerFirstName ?? ''} ${
    leadPassenger.passengerLastName ?? ''
  }`.trim()

  return <Text size="sm">{fullName}</Text>
}

export default LeadPassengerCell
