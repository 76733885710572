import { InvestigationInfo, PredictionListTypeAddToList } from '@signifyd/http'
import { i18nInstance } from '@signifyd/components'
import { DataSource } from '../../../types'
import { getInvestigationValue } from './getEvaluatedAddToListInvestigations'
import { AddToListInvestigationEvaluation } from './useGetSelectedAddToListRows'

const getAddToListInvestigationEvaluation = (
  selectedInvestigations: Array<InvestigationInfo>,
  listType: PredictionListTypeAddToList,
  dataSource?: DataSource
): AddToListInvestigationEvaluation => {
  const { t } = i18nInstance

  const evaluation = selectedInvestigations.reduce(
    (evaluation, investigation) => {
      const value = getInvestigationValue(investigation, listType, dataSource)

      const disabledReason = !value
        ? t(
            'bulkActions.bulkAddToList.addToListConfirmationModal.disabledInvestigationReason',
            {
              id: investigation.investigationId,
              listType: t(
                `bulkActions.bulkAddToList.addToListModal.listTypeStartCase.${listType}`
              ),
            }
          )
        : undefined

      evaluation.evaluatedInvestigations.push({
        investigation,
        disabledReason,
        value,
      })

      if (value) {
        evaluation.selectedAndValidIds.push(
          investigation.investigationId.toString()
        )
      }

      return evaluation
    },
    {
      evaluatedInvestigations: [],
      selectedAndValidIds: [],
    } as AddToListInvestigationEvaluation
  )

  return evaluation
}

export default getAddToListInvestigationEvaluation
