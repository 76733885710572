import { Space, Text } from '@signifyd/components'
import { Modal, Radio } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from 'antd/lib/button'
import styles from './SelectDataSourceModal.less'
import { EMAIL_TYPE, ADDRESS_TYPE, DataSource } from '../../types'
import { useBulkActionsContext } from '../../BulkActionsProvider'

interface Props {
  dataType: 'email' | 'address'
  onNext: (action: DataSource) => void
  onPrevious: () => void
  isVisible: boolean
}

const SelectDataSourceModal: FC<Props> = ({
  dataType,
  onNext,
  onPrevious,
  isVisible,
}) => {
  const { t } = useTranslation()

  const [dataSource, setDataSource] = useState<DataSource>()

  const { resetAddToList } = useBulkActionsContext()

  const okButtonProps = {
    'data-analytics-id': `bulk-actions-${dataType}-type-ok`,
    'data-test-id': 'dataSourceContinueButton',
    type: 'link',
    disabled: !dataSource,
  } as unknown as ButtonProps

  const cancelButtonProps = {
    'data-analytics-id': `bulk-actions-${dataType}-type-cancel`,
    type: 'link',
    onClick: onPrevious,
  } as unknown as ButtonProps

  const translationKey = `bulkActions.bulkAddToList.dataSourceSelectionModal`

  return (
    <Modal
      title={t(`${translationKey}.title`, { datasource: dataType })}
      open={isVisible}
      maskClosable={false}
      closable
      cancelText={t(`${translationKey}.cancelButton`)}
      cancelButtonProps={cancelButtonProps}
      okText={t(`${translationKey}.continueButton`)}
      okButtonProps={okButtonProps}
      onOk={() => {
        if (dataSource) {
          onNext(dataSource)
        }
      }}
      onCancel={() => {
        setDataSource(undefined)
        resetAddToList()
      }}
    >
      <Text size="md" data-test-id="selectDataSourceFormHint">
        {t(`${translationKey}.description`, { datasource: dataType })}
      </Text>
      {dataType === 'email' ? (
        <Radio.Group
          onChange={(e) => setDataSource(e.target.value)}
          value={dataSource}
        >
          <Radio value={EMAIL_TYPE.CONFIRMATION_EMAIL}>
            <div data-test-id="confirmationEmail" className={styles.radio}>
              {t(`${translationKey}.confirmationEmail`)}
            </div>
          </Radio>
          <Space size={4} />
          <Radio value={EMAIL_TYPE.ACCOUNT_EMAIL}>
            <div data-test-id="accountEmail" className={styles.radio}>
              {t(`${translationKey}.accountEmail`)}
            </div>
          </Radio>
        </Radio.Group>
      ) : (
        <Radio.Group
          onChange={(e) => setDataSource(e.target.value)}
          value={dataSource}
        >
          <Radio value={ADDRESS_TYPE.DELIVERY_ADDRESS}>
            <div data-test-id="deliveryAddress" className={styles.radio}>
              {t(`${translationKey}.deliveryAddress`)}
            </div>
          </Radio>
          <Space size={4} />
          <Radio value={ADDRESS_TYPE.BILLING_ADDRESS}>
            <div data-test-id="billingAddress" className={styles.radio}>
              {t(`${translationKey}.billingAddress`)}
            </div>
          </Radio>
        </Radio.Group>
      )}
    </Modal>
  )
}

export default SelectDataSourceModal
