import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Space, Text } from '@signifyd/components'
import { Table } from 'antd'
import { BulkActionOutcome } from '@signifyd/http'
import { getColumns } from './ColumnConfig'
import { TABLE_HEIGHT } from '../BulkActionsConfirmationModal/ColumnConfig'

interface Props {
  failedOutcomes: Array<BulkActionOutcome>
}

const BulkActionsReadMoreModalContent: FC<Props> = ({ failedOutcomes }) => {
  const { t } = useTranslation()

  return (
    <>
      <Text data-test-id="bulkActionsReadMoreModalTitle">
        {t('bulkActions.readMoreModal.title', { count: failedOutcomes.length })}
      </Text>
      <Space size={12} />
      <Table
        dataSource={failedOutcomes}
        columns={getColumns()}
        pagination={false}
        rowKey={(record) => record.investigationId.toString()}
        scroll={{ y: TABLE_HEIGHT }}
        bordered
      />
    </>
  )
}

export default BulkActionsReadMoreModalContent
