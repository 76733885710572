import { INV_SIMILARITY_FIELD } from '@signifyd/http'

const similarityColumnNameMap = {
  [INV_SIMILARITY_FIELD.BILLING_ADDRESS]: 'billingAddress',
  [INV_SIMILARITY_FIELD.CONFIRMATION_EMAIL]: 'recipientConfirmationEmail',
  [INV_SIMILARITY_FIELD.CONFIRMATION_PHONE]: 'recipientConfirmationPhone',
  [INV_SIMILARITY_FIELD.DELIVERY_ADDRESS]: 'recipientAddress',
  [INV_SIMILARITY_FIELD.DEVICE_ID]: 'deviceId',
  [INV_SIMILARITY_FIELD.IP_ADDRESS]: 'browserIp',
}

export const checkSimilarityColumns = (
  hiddenColumns: Array<string>,
  similarityAttribute?: INV_SIMILARITY_FIELD
): boolean => {
  if (!similarityAttribute || !hiddenColumns.length) {
    return false
  }

  return hiddenColumns.some(
    (column) => column === similarityColumnNameMap[similarityAttribute]
  )
}
