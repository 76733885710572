import { FC } from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useTablePagination } from 'pages/InvestigationSearchPage/queries/useTablePagination'
import { useTableQueryParams } from 'pages/InvestigationSearchPage/queries/useTableQueryParams'
import { useTableSorting } from 'pages/InvestigationSearchPage/queries/useTableSorting'
import useSimilarityParams from 'core/hooks/useSimilarityParams'
import { useTableContext } from 'pages/InvestigationSearchPage/containers/SearchContainer/TableProvider'
import styles from './ResetButton.less'

const ResetButton: FC = () => {
  const { t } = useTranslation()

  const { resetPagination } = useTablePagination()
  const { resetTableFilters } = useTableQueryParams()
  const { resetTableSorting } = useTableSorting()
  const similarityParams = useSimilarityParams()

  const { resetTableScroll, selectedRowKeys } = useTableContext()

  const handleReset = (): void => {
    resetPagination()
    resetTableFilters()
    resetTableSorting()
    resetTableScroll()

    similarityParams?.resetSimilarityParams()
  }

  return (
    <Button
      type="link"
      onClick={handleReset}
      className={styles.button}
      data-test-id="resetButton"
      data-analytics-id="reset-button"
      disabled={!!selectedRowKeys.length}
    >
      {t('investigationSearch.resetSearch')}
    </Button>
  )
}

export default ResetButton
