import { i18nInstance } from '@signifyd/components'
import { ColumnProps } from 'antd/lib/table'
import { BulkActionOutcome } from '@signifyd/http'
import styles from './BulkActionsReadMoreModalContent.less'

type ReadMoreTableColumnProps = ColumnProps<BulkActionOutcome>

export const getColumns = (): Array<ReadMoreTableColumnProps> => {
  return [
    {
      title: i18nInstance.t('bulkActions.readMoreModal.columns.caseId'),
      dataIndex: 'investigationId',
      key: 'investigationId',
      width: 80,
    },
    {
      title: i18nInstance.t('bulkActions.readMoreModal.columns.failureReason'),
      dataIndex: 'failureReason',
      key: 'failureReason',
      width: 200,
      className: styles.truncate,
    },
  ]
}
