import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Space, Text } from '@signifyd/components'
import { Alert, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { Key } from 'antd/es/table/interface'
import {
  EvaluatedInvestigation,
  InvestigationEvaluation,
} from 'pages/InvestigationSearchPage/hooks/useGetEvaluatedInvestigations'
import { TABLE_HEIGHT } from './ColumnConfig'
import styles from './BulkActionsConfirmationModalContent.less'

interface Props {
  selectedRowKeys: Array<Key>
  setSelectedRowKeys: (selectedRowKeys: Array<Key>) => void
  evaluation?: InvestigationEvaluation
  isLoading?: boolean
  description?: ReactNode
  getColumns: () => Array<ColumnProps<EvaluatedInvestigation>>
}

const BulkActionsConfirmationModalContent: FC<Props> = ({
  selectedRowKeys,
  setSelectedRowKeys,
  evaluation,
  isLoading,
  description,
  getColumns,
}) => {
  const { t } = useTranslation()

  const handleSelectChange = (selectedRowKeys: Array<Key>): void => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const disabledInvestigations = evaluation?.evaluatedInvestigations?.filter(
    (investigation) => !!investigation.disabledReason
  )

  const showDisabledCaseAlert = !!disabledInvestigations?.length

  const allOrdersAreInvalid =
    disabledInvestigations?.length ===
    evaluation?.evaluatedInvestigations?.length

  return (
    <div data-test-id="confirmationModalContentContainer">
      <Text data-test-id="bulkActionsConfirmationModalDescription">
        {description}{' '}
        {!!selectedRowKeys.length &&
          t('bulkActions.confirmationModal.submitMessage', {
            count: selectedRowKeys.length,
          })}
      </Text>
      <Space size={12} />
      {showDisabledCaseAlert && (
        <Alert
          message=""
          description={
            allOrdersAreInvalid
              ? t('bulkActions.confirmationModal.alertMessageZeroOrders')
              : t('bulkActions.confirmationModal.alertMessage')
          }
          type="warning"
          data-test-id="disabledOrdersAlertMessage"
          showIcon
        />
      )}
      <Space size={12} />
      <Table
        dataSource={evaluation?.evaluatedInvestigations}
        columns={getColumns()}
        pagination={false}
        rowKey={({ investigation }) => investigation.investigationId.toString()}
        scroll={{ y: TABLE_HEIGHT }}
        rowSelection={{
          selectedRowKeys,
          onChange: handleSelectChange,
          getCheckboxProps: (record: EvaluatedInvestigation) => ({
            disabled: !!record.disabledReason,
            'data-test-id': `confirmationCheckbox-${record.investigation.investigationId}`,
            'data-analytics-id': 'confirmation-checkbox',
          }),
        }}
        rowClassName={(evaluatedInvestigation) =>
          evaluatedInvestigation.disabledReason ? styles.disabledRow : ''
        }
        loading={isLoading}
      />
    </div>
  )
}

export default BulkActionsConfirmationModalContent
