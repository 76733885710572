import { ReactNode } from 'react'
import { InvestigationInfo } from '@signifyd/http'
import { ExternalLink } from '@signifyd/components'

const TeamCell = (_text: null, rowData: InvestigationInfo): ReactNode =>
  rowData.teamName && (
    <ExternalLink url={`/casesearch?teamId=${rowData.teamId}`}>
      {rowData.teamName}
    </ExternalLink>
  )

export default TeamCell
