import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { UserData } from 'core/hooks/useGetUserData'
import styles from './SearchTitleBar.less'
import { CustomerSelect, TeamSelect } from '../TeamAndCustomerSelects'

interface Props {
  userData: UserData
}

const SearchTitleBar: FC<Props> = ({ userData }: Props) => {
  const { t } = useTranslation()

  return (
    <Row
      align="middle"
      justify="space-between"
      className={styles.searchContent}
    >
      <Col>
        <span className={styles.searchTitle} data-test-id="searchTitle">
          {t('investigationSearch.search')}
        </span>
      </Col>
      <Col className={styles.container}>
        {userData.isAdmin && <CustomerSelect />}
        <TeamSelect />
      </Col>
    </Row>
  )
}

export default SearchTitleBar
