import {
  BULK_ACTION_OUTCOME_RESULT,
  BulkActionRequest,
  performBulkAction,
} from '@signifyd/http'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import { partition } from 'lodash'
import { BulkActionOutcome } from '@signifyd/http/dist/v3/bulkactions/types/http'

export interface BulkActionEvaluatedResponse {
  allOutcomes: Array<BulkActionOutcome>
  successOutcomes: Array<BulkActionOutcome>
  failedOutcomes: Array<BulkActionOutcome>
}

const usePerformBulkActions = (): UseMutationResult<
  BulkActionEvaluatedResponse,
  unknown,
  BulkActionRequest
> => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation(
    async (bulkActionRequest) => {
      const {
        data: { outcomes: allOutcomes },
      } = await performBulkAction(bulkActionRequest)

      const [failedOutcomes, successOutcomes] = partition(
        allOutcomes,
        ({ result }) => result === BULK_ACTION_OUTCOME_RESULT.FAILED
      )

      return { allOutcomes, successOutcomes, failedOutcomes }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['searchResults'])
      },
      onError: () => {
        message.error(t('bulkActions.performBulkActionsError'))
      },
    }
  )
}

export default usePerformBulkActions
