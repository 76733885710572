export const MESSAGE_TYPES = {
  ERROR: 'error',
  SUCCESS: 'success',
}

export const USER_ROLES = {
  CLAIMS: 'CLAIMS',
  ADMIN: 'ADMIN',
  TRAINING: 'TRAINING',
  MANUAL_REVIEWER: 'MANUAL_REVIEWER',
  MANUAL_REVIEWER_LEAD: 'MANUAL_REVIEWER_LEAD',
  MANUAL_REVIEWER_ADMIN: 'MANUAL_REVIEWER_ADMIN',
  BETA: 'BETA',
}

export const UNKNOWN = 'Unknown'

export const DEFAULT_TIMEZONE = 'US/Pacific'

export const GUARANTEE_DISPOSITION = {
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  PENDING: 'PENDING',
  IN_REVIEW: 'IN_REVIEW',
  CANCELED: 'CANCELED',
  CANCELLED: 'CANCELLED',
  ESCALATED: 'ESCALATED',
}

export const GUARANTEE_PLAN_TYPES = {
  DEFAULT: 'DEFAULT',
  LIVE: 'LIVE',
  TEST: 'TEST',
  TRIAL: 'TRIAL',
  UNRECOGNIZED: 'UNRECOGNIZED',
}

export const ORDERS_APP_URL =
  process.env.BUILD_ENV === 'production'
    ? 'https://console.signifyd.com/orders'
    : 'https://console.staging.signifyd.com/orders'

export const OLD_CONSOLE_URL =
  process.env.BUILD_ENV === 'production'
    ? 'https://app.signifyd.com/cases'
    : 'https://app.staging.signifyd.com/cases'
