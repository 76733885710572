import { FC } from 'react'
import { Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { ColFilterKey } from 'pages/InvestigationSearchPage/containers/SearchResultsTable/ColumnFilterConfig'

interface Props {
  value: string
  onChange: (arg: string) => void
  onEnterPress: () => void
  columnKey: ColFilterKey
  type: 'text' | 'number'
}

const TextInput: FC<Props> = ({
  value,
  onChange,
  onEnterPress,
  columnKey,
  type,
}) => {
  const { t } = useTranslation()

  return (
    <Input
      placeholder={t('columnFilters.inputs.textInput.placeholder')}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onKeyPress={(e) => e.key === 'Enter' && onEnterPress()}
      data-analytics-id={`column-filter-text-input-${columnKey}`}
      data-test-id="textFilterInput"
      type={type}
    />
  )
}

export default TextInput
