import { FC } from 'react'
import { Button } from 'antd'
import styles from './MultiComponentPopover.less'

interface Props {
  amount: number
}

const MultiComponentPopoverButton: FC<Props> = ({ amount }) => {
  return (
    <Button className={styles.componentPopoverBtn} shape="circle">
      +{amount}
    </Button>
  )
}

export default MultiComponentPopoverButton
