import Icon from '@ant-design/icons'
import { FC, ReactElement } from 'react'
import { colorYale, colorEmerald, colorEaton } from '@signifyd/colors'
import {
  GuaranteeIcon,
  GuaranteeTag,
  SigTag,
  i18nInstance,
} from '@signifyd/components'
import { INV_GUARANTEE_DISPOSITION } from '@signifyd/http'
import {
  GenericDispositionTags,
  getGenericDispositionsTags,
} from './dispositionTags.utils'

type CustomerDispositionTags = Extract<
  INV_GUARANTEE_DISPOSITION,
  | INV_GUARANTEE_DISPOSITION.CANCELED
  | INV_GUARANTEE_DISPOSITION.PENDING
  | INV_GUARANTEE_DISPOSITION.APPROVED
  | INV_GUARANTEE_DISPOSITION.DECLINED
>

interface Props {
  text: INV_GUARANTEE_DISPOSITION
}
interface IconProps {
  fill: string
  component: React.FC
}

const customerGuaranteeItems: Array<INV_GUARANTEE_DISPOSITION> = [
  INV_GUARANTEE_DISPOSITION.CANCELED,
  INV_GUARANTEE_DISPOSITION.PENDING,
  INV_GUARANTEE_DISPOSITION.APPROVED,
  INV_GUARANTEE_DISPOSITION.DECLINED,
]

const CanceledGuaranteeIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.84178 15.351L6.58608 14.652L7.89527 16L9.23931 14.6867L10.9647 15.4311L11.7039 13.7034L13.5828 13.7309L13.6045 11.8519L15.351 11.1582L14.652 9.41392L16 8.10473L14.6867 6.76069L15.4311 5.03529L13.7034 4.29614L13.7309 2.4172L11.8519 2.39546L11.1582 0.649041L9.41392 1.34803L8.10473 0L6.76069 1.31329L5.03529 0.568887L4.29614 2.29655L2.4172 2.26909L2.39546 4.14811L0.649041 4.84178L1.34803 6.58608L0 7.89527L1.31329 9.23931L0.568887 10.9647L2.29655 11.7039L2.26909 13.5828L4.14811 13.6045L4.84178 15.351ZM3.3335 8.00016C3.3335 5.42308 5.42308 3.3335 8.00016 3.3335C10.5772 3.3335 12.6668 5.42308 12.6668 8.00016C12.6668 10.5772 10.5772 12.6668 8.00016 12.6668C5.42308 12.6668 3.3335 10.5772 3.3335 8.00016ZM4.12516 8.00016C4.12516 10.1397 5.86058 11.8752 8.00016 11.8752C8.92725 11.8752 9.77933 11.5491 10.446 11.0054L4.99495 5.55433C4.4512 6.221 4.12516 7.07308 4.12516 8.00016ZM5.55433 4.99495L11.0054 10.446C11.5491 9.77933 11.8752 8.92725 11.8752 8.00016C11.8752 5.86058 10.1397 4.12516 8.00016 4.12516C7.07308 4.12516 6.221 4.4512 5.55433 4.99495Z"
        fill="white"
      />
    </svg>
  )
}

const TransparentIcon: React.FC = () => {
  return <GuaranteeIcon transparent fill="white" />
}

const customerIconConfig: Record<CustomerDispositionTags, IconProps> = {
  [INV_GUARANTEE_DISPOSITION.CANCELED]: {
    fill: colorEaton,
    component: CanceledGuaranteeIcon,
  },
  [INV_GUARANTEE_DISPOSITION.PENDING]: {
    fill: colorYale,
    component: TransparentIcon,
  },
  [INV_GUARANTEE_DISPOSITION.DECLINED]: {
    fill: colorEaton,
    component: TransparentIcon,
  },
  [INV_GUARANTEE_DISPOSITION.APPROVED]: {
    fill: colorEmerald,
    component: TransparentIcon,
  },
}

const genericDispositionItems: Array<INV_GUARANTEE_DISPOSITION> = [
  INV_GUARANTEE_DISPOSITION.PROCESSING,
  INV_GUARANTEE_DISPOSITION.PROCESSING_REGUARANTEE,
  INV_GUARANTEE_DISPOSITION.IN_REVIEW,
]

const CustomerDispositionTag: FC<Props> = ({ text }: Props): ReactElement => {
  if (customerGuaranteeItems.includes(text)) {
    const { fill, component } =
      customerIconConfig[text as CustomerDispositionTags]

    return (
      <SigTag
        type="secondary"
        color={fill}
        data-test-id={`customerDisposition-${text}`}
      >
        <Icon component={component} />
        <span>{i18nInstance.t(`icon.guaranteeDisposition.${text}`)}</span>
      </SigTag>
    )
  }

  if (genericDispositionItems.includes(text)) {
    return getGenericDispositionsTags(text as GenericDispositionTags)
  }

  return <GuaranteeTag disposition={text} showText />
}

export default CustomerDispositionTag
