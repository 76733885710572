import bulkAddToList from './bulkActions/bulkAddToList'

export default {
  buttons: {
    submitGuaranteeButton: 'Submit for Guarantee',
    dislikeButton: 'Mark as bad',
    notesButton: 'Add notes',
    addToListButton: 'Add to list',
  },
  bulkAddToListButton: {
    tooltip: {
      noLists: 'To add to list, select a team with lists enabled in Orders',
      tooManyTeamsSelected:
        "You've selected investigations from {{count}} different teams. Items may only be added to list from a single team.",
    },
  },
  selectedText: 'selected',
  performBulkActionsError: 'There was an error in performing this bulk action',
  notesModal: {
    modalTitle: 'Add a note',
    okText: 'Continue',
    helpText: 'Please include as much detail as possible to help our analysts',
    additionalFiles: 'Additional files',
    addNotesPlaceholder: 'Add a note',
    actionButtons: {
      SUBMIT_FOR_GUARANTEE_one:
        'There is {{count}} order selected for <bold>Guarantee</bold>. To maintain an audit trail, add a note outlining the reasoning for this bulk action.',
      SUBMIT_FOR_GUARANTEE_other:
        'There are {{count}} orders selected for <bold>Guarantee</bold>. To maintain an audit trail, add a note outlining the reasoning for this bulk action.',
      THUMBS_DOWN_one:
        'There is {{count}} order selected for <bold>Mark as bad</bold>. To maintain an audit trail, add a note outlining the reasoning for this bulk action.',
      THUMBS_DOWN_other:
        'There are {{count}} orders selected for <bold>Mark as bad</bold>. To maintain an audit trail, add a note outlining the reasoning for this bulk action.',
      ADD_NOTE_one:
        '<bold>Notes</bold> are being added to {{count}} selected order',
      ADD_NOTE_other:
        '<bold>Notes</bold> are being added to {{count}} selected orders',
      ADD_TO_LIST_one:
        'There is {{count}} <lowercase>{{listType}}</lowercase> selected to be added to list ‘<bold>{{listName}}</bold>’. To maintain an audit trail, add a note outlining the reasoning for this bulk action.',
      ADD_TO_LIST_other:
        'There are {{count}} <lowercase>{{listType}}</lowercase> selected to be added to list ‘<bold>{{listName}}</bold>’. To maintain an audit trail, add a note outlining the reasoning for this bulk action.',
    },
  },
  confirmationModal: {
    modalTitle: 'Confirm bulk action',
    okText: 'Submit',
    cancelText: 'Back',
    downloadCsv: 'Download CSV',
    alertMessage:
      'Some orders are ineligible for bulk actioning. Use the tooltip beside each exempt order below to understand more.',
    alertMessageZeroOrders:
      'Orders are ineligible for bulk actioning. Use the tooltip beside each exempt order below to understand more.',
    tooOldForSubmit:
      "Order can't be submitted for guarantee because it's older than 7 days",
    requiresDisposition:
      'To submit for Guarantee, orders require a thumbs up disposition first',
    hasClaim: 'Order cannot be submitted when the order has a claim',
    notEligible: 'Order ineligible for guarantee',
    alreadyMarkedAsBad: 'Order is already marked as bad',
    resubmit: {
      reason: 'Order ineligible to be submitted for guarantee',
      reason_apiFailure:
        'Currently unable to determine eligibility for this order',
      reason_TEST_CASE: 'Test orders cannot be submitted for guarantee',
      reason_TRIAL_CASE:
        'Order submitted during trial and cannot be submitted for guarantee',
      reason_CASE_OUTDATED:
        'Order is too old and cannot be submitted for guarantee',
      reason_GUARANTEED_PAYMENTS_CASE_EXISTS:
        'Order is already guaranteed and cannot be submitted for guarantee',
      reason_INELIGIBLE_GUARANTEE_STATUS:
        'Only orders that have been declined may be submitted for guarantee',
      reason_ABOVE_MAX_ORDER_AMOUNT:
        'Order amount is too high and cannot be submitted for guarantee',
      reason_BELOW_MIN_ORDER_AMOUNT:
        'Order amount is too low and cannot be submitted for guarantee',
      reason_EMPTY_ORDER_AMOUNT:
        'Orders without an amount cannot be submitted for guarantee',
      reason_DUPLICATE_ORDER_EXISTS:
        'Order was already guaranteed in previous investigation',
      reason_TOO_MANY_UPDATES:
        'Order has too many decisions and cannot be submitted for guarantee',
      reason_SANCTIONED_COUNTRY:
        'Order IP geolocation or delivery country is restricted and cannot be submitted for guarantee',
      reason_INELIGIBLE_CUSTOMER:
        'Account ineligible to submit orders for guarantee',
      reason_INELIGIBLE_PARTNER:
        'Partner account ineligible to submit orders for guarantee',
    },
    modalDescription: {
      SUBMIT_FOR_GUARANTEE_one:
        'There is {{count}} order about to be submitted for <bold>Guarantee</bold>.',
      SUBMIT_FOR_GUARANTEE_other:
        'There are {{count}} orders about to be submitted for <bold>Guarantee</bold>.',
      THUMBS_DOWN_one:
        'There is {{count}} order about to be submitted for <bold>Mark as bad</bold>.',
      THUMBS_DOWN_other:
        'There are {{count}} orders about to be submitted for <bold>Mark as bad</bold>.',
      ADD_NOTE_one:
        '<bold>Notes</bold> are about to be added to {{count}} selected order.',
      ADD_NOTE_other:
        '<bold>Notes</bold> are about to be added to {{count}} selected orders.',
      ADD_TO_LIST_one:
        'There is {{count}} {{listType}} selected to be added to list ‘<bold>{{listName}}</bold>’',
      ADD_TO_LIST_other:
        'There are {{count}} {{listType}} selected to be added to list ‘<bold>{{listName}}</bold>’',
    },
    submitMessage_one:
      'Submit to confirm this action for the order summarized in the table below.',
    submitMessage_other:
      'Submit to confirm this action for the orders summarized in the table below.',
    columns: {
      caseId: 'CASE ID',
      team: 'TEAM',
      orderTotal: 'ORDER TOTAL',
    },
  },
  notification: {
    readMore: 'Read more',
    eligibilityApiFailure: 'Unable to verify case guarantee eligibility',
    message: {
      error: 'Bulk action incomplete',
      success: 'Bulk action complete',
      open: 'Bulk action in progress',
    },
    description: {
      open: 'Your selected orders have been submitted for Guarantee, this may take a few minutes.',
      error_one: '{{count}} Order failed to update',
      error_other: '{{count}} Orders failed to update',
      success_one: '{{count}} Order successfully updated',
      success_other: '{{count}} Orders successfully updated',
    },
    descriptionTotal: {
      error: 'Update failed on {{subset}} of {{count}} orders selected',
      success: 'Update successful on {{subset}} of {{count}} orders selected',
      allerror: 'Update failed on all orders selected',
      allsuccess: 'Update successful on all orders selected',
    },
  },
  readMoreModal: {
    modalTitle: 'Bulk action incomplete',
    title_one:
      '{{ count }} order failed to update as summarized in the table below',
    title_other:
      '{{ count }} orders failed to update as summarized in the table below',
    okText_one: 'Show order',
    okText_other: 'Show orders',
    cancelText: 'Close',
    columns: {
      caseId: 'CASE ID',
      failureReason: 'FAILURE REASON',
    },
  },
  bulkAddToList,
}
