import { FC } from 'react'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { SEARCH_FIELD, SEARCH_ORDER } from '@signifyd/http'
import cx from 'classnames'
import { useTableContext } from 'pages/InvestigationSearchPage/containers/SearchContainer/TableProvider'
import styles from './OrderIcon.less'

export interface Props {
  currentOrder?: SEARCH_ORDER | null
  onClick: (value: SEARCH_ORDER) => void
  columnKey: SEARCH_FIELD
}

const OrderIcon: FC<Props> = ({ currentOrder, onClick, columnKey }) => {
  const { saveCurrentColumnPosition } = useTableContext()
  const { selectedRowKeys } = useTableContext()
  const isDisabled = !!selectedRowKeys.length

  const containerClasses = cx(styles.orderIcon, isDisabled && styles.disabled)

  const handleClick = (searchOrder: SEARCH_ORDER): void => {
    if (!isDisabled) {
      saveCurrentColumnPosition()
      onClick(searchOrder)
    }
  }

  return (
    <div className={containerClasses}>
      <CaretUpOutlined
        className={cx(
          styles.orderIconUp,
          currentOrder === SEARCH_ORDER.ASCENDING && styles.orderIconSelected
        )}
        onClick={() => {
          handleClick(SEARCH_ORDER.ASCENDING)
        }}
        data-test-id="upSortIcon"
        data-analytics-id={`up-sort-icon-${columnKey}`}
      />
      <CaretDownOutlined
        className={cx(
          styles.orderIconDown,
          currentOrder === SEARCH_ORDER.DESCENDING && styles.orderIconSelected
        )}
        onClick={() => {
          handleClick(SEARCH_ORDER.DESCENDING)
        }}
        data-test-id="downSortIcon"
        data-analytics-id={`down-sort-icon-${columnKey}`}
      />
    </div>
  )
}

export default OrderIcon
