import { FC } from 'react'
import { InvestigationInfo } from '@signifyd/http'
import { Text } from '@signifyd/components'
import { isTransportAirline } from '../airline.utils'

interface Props {
  products: InvestigationInfo['products']
}

const RecordLocatorCell: FC<Props> = ({ products }) => {
  if (!isTransportAirline(products)) {
    return null
  }

  const passengerNameRecord = products.find((product) => !!product.itemId)

  if (!passengerNameRecord?.itemId) {
    return null
  }

  // For Airline investigations the passenger name record is the products itemId and should always be the same
  return <Text size="sm">{passengerNameRecord.itemId}</Text>
}

export default RecordLocatorCell
