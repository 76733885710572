import { FC } from 'react'
import { EntityTransferSelect, ENTITY_TYPE } from '@signifyd/components'

import { useTableQueryParams } from 'pages/InvestigationSearchPage/queries/useTableQueryParams'
import { useTablePagination } from 'pages/InvestigationSearchPage/queries/useTablePagination'
import { useTableContext } from 'pages/InvestigationSearchPage/containers/SearchContainer/TableProvider'

const CustomerSelect: FC = () => {
  const { query, setQuery } = useTableQueryParams()
  const { setPagination } = useTablePagination()
  const { selectedRowKeys } = useTableContext()

  const initiallySelectedCustomers =
    query.customerId?.filter((key): key is string => !!key) ?? []

  return (
    <>
      <EntityTransferSelect
        entityType={ENTITY_TYPE.CUSTOMER}
        onSave={(selectedKeys) => {
          setQuery({ customerId: selectedKeys })
          setPagination({ current: 1 })
        }}
        selectedKeys={initiallySelectedCustomers}
        disabled={!!selectedRowKeys.length}
      />
    </>
  )
}

export default CustomerSelect
