import {
  listPredictionLists,
  PredictionList,
  PREDICTION_LIST_TYPE,
} from '@signifyd/http'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useBulkActionsContext } from '../BulkActionsProvider'

export type BulkActionsPredictionListMap = Partial<
  Record<PREDICTION_LIST_TYPE, Array<PredictionList>>
>

export interface PredictionListsData {
  lists: BulkActionsPredictionListMap
  hasListsForTeam: boolean
}

const getDefaultLists = (): BulkActionsPredictionListMap => {
  const defaultLists: BulkActionsPredictionListMap = {
    [PREDICTION_LIST_TYPE.ADDRESS]: [],
    [PREDICTION_LIST_TYPE.IP_ADDRESS]: [],
    [PREDICTION_LIST_TYPE.EMAIL]: [],
    [PREDICTION_LIST_TYPE.DEVICE_ID]: [],
    [PREDICTION_LIST_TYPE.PHONE_NUMBER]: [],
    [PREDICTION_LIST_TYPE.ACCOUNT_NUMBER]: [],
    [PREDICTION_LIST_TYPE.TAX_ID]: [],
  }

  return defaultLists
}

export const useGetIsBulkAddToListEnabled = (): UseQueryResult<boolean> => {
  const { t } = useTranslation()

  const { allInvestigations } = useBulkActionsContext()

  const allTeamIds = [
    ...new Set([...allInvestigations.map(({ teamId }) => teamId)]),
  ]

  // We won't show the add to list flow if none of the orders in the current table slice have orders enabled lists
  return useQuery(
    ['bulkActionAllPredictionLists', allTeamIds],
    async () => {
      const supportedListTypes = Object.keys(
        getDefaultLists()
      ) as Array<PREDICTION_LIST_TYPE>

      const {
        data: { data: allLists },
      } = await listPredictionLists({
        teamIds: allTeamIds.join(','),
        types: supportedListTypes,
        isShownInOrdersApp: true,
      })

      return !!allLists.length
    },
    {
      enabled: !!allTeamIds.length,
      onError: () => {
        message.error(t('bulkActions.bulkAddToList.fetchListsFailure'))
      },
    }
  )
}

export const useGetBulkActionPredictionLists =
  (): UseQueryResult<PredictionListsData> => {
    const { t } = useTranslation()

    const { selectedInvestigations } = useBulkActionsContext()

    const selectedTeamIds = [
      ...new Set([...selectedInvestigations.map(({ teamId }) => teamId)]),
    ]

    return useQuery(
      ['bulkActionSelectedPredictionLists', selectedTeamIds],
      async () => {
        const defaultLists = getDefaultLists()
        const supportedListTypes = Object.keys(
          defaultLists
        ) as Array<PREDICTION_LIST_TYPE>

        // We don't allow add to list for more then one team at a time
        if (selectedTeamIds.length !== 1) {
          return {
            lists: defaultLists,
            hasListsForTeam: false,
          }
        }

        const {
          data: { data: rawLists },
        } = await listPredictionLists({
          teamIds: selectedTeamIds.join(','),
          types: supportedListTypes,
          isShownInOrdersApp: true,
        })

        const lists = rawLists.reduce((listsData, list) => {
          listsData[list.type]?.push(list)

          return listsData
        }, defaultLists)

        return {
          lists,
          hasListsForTeam: !!rawLists.length,
        }
      },
      {
        enabled: !!selectedTeamIds.length,
        onError: () => {
          message.error(t('bulkActions.bulkAddToList.fetchListsFailure'))
        },
      }
    )
  }
