import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Space } from '@signifyd/components'
import { Alert, Col, Row } from 'antd'
import SimilarityParamTag from 'pages/InvestigationSearchPage/components/SimilarityParamTag'
import ResetButton from 'pages/InvestigationSearchPage/components/ResetButton'
import SearchResults from 'pages/InvestigationSearchPage/components/SearchResults/SearchResults'
import SearchBar from 'pages/InvestigationSearchPage/components/SearchBar'
import SearchTypeSwitch from 'pages/InvestigationSearchPage/components/SearchTypeSwitch/SearchTypeSwitch'
import SearchDatePicker from 'pages/InvestigationSearchPage/components/SearchDatePicker/SearchDatePicker'
import { useTableContext } from 'pages/InvestigationSearchPage/containers/SearchContainer/TableProvider'
import { UserData } from 'core/hooks/useGetUserData'
import styles from './SearchBarContainer.less'

interface Props {
  userData: UserData
}

const SearchBarContainer: FC<Props> = ({ userData }) => {
  const { t } = useTranslation()
  const { selectedRowKeys } = useTableContext()
  const isDisabled = !!selectedRowKeys.length

  return (
    <>
      {isDisabled && (
        <>
          <Space size="md" />
          <Alert
            message=""
            description={t('investigationSearch.disabledSearch')}
            type="warning"
            data-test-id="disabledSearchAlertMessage"
            showIcon
          />
        </>
      )}
      <Space size="md" />
      <SearchBar />
      <Space size="md" />
      <Row justify="space-between">
        <Col className={styles.layout}>
          <SearchResults />
          <SearchDatePicker userData={userData} />
          <SearchTypeSwitch />
        </Col>
        <Col className={styles.layout}>
          <SimilarityParamTag />
          <ResetButton />
        </Col>
      </Row>
      <Space size="md" />
    </>
  )
}

export default SearchBarContainer
