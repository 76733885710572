import { FC } from 'react'
import { Space } from '@signifyd/components'
import { Col, Row } from 'antd'
import SearchPagination from 'pages/InvestigationSearchPage/components/SearchPagination'
import TableContainer from 'pages/InvestigationSearchPage/containers/SearchResultsTable'
import DownloadButton from 'pages/InvestigationSearchPage/components/DownloadButton'
import ColumnDrawer from 'pages/InvestigationSearchPage/components/ColumnDrawer'
import styles from './SearchContainer.less'

const featureColumnDrawerEnabled = process.env.BUILD_ENV === 'local'

const SearchContainer: FC = () => {
  return (
    <div className={styles.container} data-test-id="searchContainer">
      <>
        <Space size="md" />
        <Row justify="space-between">
          <Col>
            <SearchPagination />
          </Col>
          <Col>
            {featureColumnDrawerEnabled && <ColumnDrawer />}
            <DownloadButton />
          </Col>
        </Row>

        <Space size="md" />
        <div className={styles.content}>
          <TableContainer />
        </div>
      </>
    </div>
  )
}

export default SearchContainer
