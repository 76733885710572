import { FC, useState } from 'react'
import {
  DUPLICATE_PREDICTION_LIST_ENTRY_ACTION,
  InvestigationValue,
  PREDICTION_LIST_TYPE,
  PredictionList,
  PredictionListTypeAddToList,
} from '@signifyd/http'
import { Trans, useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import ResolveDuplicatesModal, {
  DEFAULT_DUPLICATE_ACTION,
} from 'pages/InvestigationSearchPage/components/BulkActions/AddToList/ResolveDuplicatesModal/ResolveDuplicatesModal'
import BulkActionsConfirmationModal from 'pages/InvestigationSearchPage/components/BulkActions/BulkActionsConfirmationModal'
import { useBulkActionsContext } from 'pages/InvestigationSearchPage/components/BulkActions/BulkActionsProvider'
import { DataSource } from 'pages/InvestigationSearchPage/components/BulkActions/types'
import useAddToListEvaluatedData from './useGetSelectedAddToListRows'
import useBulkAddToList from './useBulkAddToList'
import { getAddToListColumns } from '../../../BulkActionsConfirmationModal/ColumnConfig'

interface Props {
  predictionListType: PredictionListTypeAddToList
  selectedList: PredictionList
  dataSource?: DataSource
}

const AddToListConfirmationModal: FC<Props> = ({
  selectedList,
  predictionListType,
  dataSource,
}) => {
  const { t } = useTranslation()

  const { bulkActionStage, setBulkActionStage, bulkActionType } =
    useBulkActionsContext()

  const { selectedRows, setSelectedRows, evaluation } =
    useAddToListEvaluatedData(predictionListType, dataSource)

  const [duplicateAction, setDuplicateAction] = useState(
    DUPLICATE_PREDICTION_LIST_ENTRY_ACTION.UNKNOWN
  )

  const [hasDuplicateInvestigations, setHasDuplicateInvestigations] =
    useState(false)

  const { mutate, isLoading } = useBulkAddToList({
    onRejectionWithDuplicates: () => {
      setHasDuplicateInvestigations(true)
      setDuplicateAction(DEFAULT_DUPLICATE_ACTION)
    },
  })

  const handleSubmit = async (
    duplicateAction: DUPLICATE_PREDICTION_LIST_ENTRY_ACTION
  ): Promise<void> => {
    const investigationValues: Array<InvestigationValue> = []

    selectedRows.forEach((id) => {
      const evaluatedInvestigation = evaluation.evaluatedInvestigations.find(
        (evaluatedInvestigation) =>
          evaluatedInvestigation.investigation.investigationId === Number(id)
      )

      if (evaluatedInvestigation?.value) {
        const {
          value,
          investigation: { investigationId },
        } = evaluatedInvestigation

        investigationValues.push({
          investigationId,
          value,
        })
      }
    })

    mutate({
      listId: selectedList.id,
      type: predictionListType,
      duplicateEntryAction: duplicateAction,
      investigationValues,
    })
  }

  const handleSelectDuplicateAction = (
    action: DUPLICATE_PREDICTION_LIST_ENTRY_ACTION
  ): void => {
    setDuplicateAction(action)

    handleSubmit(action)
  }

  const listSource =
    selectedList?.type === PREDICTION_LIST_TYPE.EMAIL ||
    selectedList?.type === PREDICTION_LIST_TYPE.ADDRESS
      ? `bulkActions.bulkAddToList.dataSourceFields.${dataSource}`
      : `bulkActions.bulkAddToList.addToListModal.listTypeStartCase.${selectedList?.type}`

  if (!bulkActionType) {
    return null
  }

  const modalDescription = (
    <Trans
      i18nKey={`bulkActions.confirmationModal.modalDescription.${bulkActionType}`}
      values={{
        count: selectedRows.length,
        ...(selectedList
          ? {
              listType: t(listSource, {
                count: selectedRows.length,
                defaultValue: '',
              }),
              listName: selectedList.name,
            }
          : {}),
      }}
      components={{ bold: <Text weight="semibold" /> }}
    />
  )

  return (
    <>
      <BulkActionsConfirmationModal
        isVisible={
          bulkActionStage === 'CONFIRMATION' && !hasDuplicateInvestigations
        }
        evaluation={evaluation}
        isLoading={isLoading}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        onSubmit={() => handleSubmit(duplicateAction)}
        onPrevious={() => {
          setBulkActionStage('NOTES')
        }}
        modalDescription={modalDescription}
        getColumns={() =>
          getAddToListColumns(
            t(
              `bulkActions.bulkAddToList.addToListModal.listTypeStartCase.${predictionListType}`
            )
          )
        }
      />
      <ResolveDuplicatesModal
        isVisible={
          bulkActionStage === 'CONFIRMATION' && hasDuplicateInvestigations
        }
        onSelect={handleSelectDuplicateAction}
        onBack={() => {
          setDuplicateAction(DUPLICATE_PREDICTION_LIST_ENTRY_ACTION.UNKNOWN)
          setHasDuplicateInvestigations(false)
        }}
        isLoading={isLoading}
        modalDescription={modalDescription}
      />
    </>
  )
}

export default AddToListConfirmationModal
