import {
  CanceledIcon,
  GuaranteeIcon,
  ProcessingIcon,
  ReviewIcon,
  i18nInstance,
} from '@signifyd/components'
import { colorSlate, colorEaton, colorYale } from '@signifyd/colors'
import { INV_GUARANTEE_DISPOSITION } from '@signifyd/http'

const iconStyle = { fontSize: '24px' }

export type GenericDispositionTags = Extract<
  INV_GUARANTEE_DISPOSITION,
  | INV_GUARANTEE_DISPOSITION.IN_REVIEW
  | INV_GUARANTEE_DISPOSITION.CANCELED
  | INV_GUARANTEE_DISPOSITION.PENDING
  | INV_GUARANTEE_DISPOSITION.PROCESSING
  | INV_GUARANTEE_DISPOSITION.PROCESSING_REGUARANTEE
>

export const getGenericDispositionsTags = (
  tagType: GenericDispositionTags
): JSX.Element => {
  const tags = {
    [INV_GUARANTEE_DISPOSITION.IN_REVIEW]: (
      <ReviewIcon
        style={iconStyle}
        fill={colorSlate}
        title={i18nInstance.t(
          `icon.guaranteeDisposition.${INV_GUARANTEE_DISPOSITION.IN_REVIEW}`
        )}
      />
    ),
    [INV_GUARANTEE_DISPOSITION.CANCELED]: (
      <CanceledIcon
        style={iconStyle}
        fill={colorEaton}
        title={i18nInstance.t(
          `icon.guaranteeDisposition.${INV_GUARANTEE_DISPOSITION.CANCELED}`
        )}
      />
    ),
    [INV_GUARANTEE_DISPOSITION.PENDING]: (
      <GuaranteeIcon
        style={iconStyle}
        fill={colorYale}
        title={i18nInstance.t(
          `icon.guaranteeDisposition.${INV_GUARANTEE_DISPOSITION.PENDING}`
        )}
      />
    ),
    [INV_GUARANTEE_DISPOSITION.PROCESSING]: (
      <ProcessingIcon
        style={iconStyle}
        fill={colorSlate}
        title={i18nInstance.t(
          `icon.guaranteeDisposition.${INV_GUARANTEE_DISPOSITION.PROCESSING}`
        )}
      />
    ),
    [INV_GUARANTEE_DISPOSITION.PROCESSING_REGUARANTEE]: (
      <ProcessingIcon
        style={iconStyle}
        fill={colorSlate}
        title={i18nInstance.t(
          `icon.guaranteeDisposition.${INV_GUARANTEE_DISPOSITION.PROCESSING}`
        )}
      />
    ),
  }

  return tags[tagType]
}
