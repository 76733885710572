import { FC } from 'react'
import NavigationWrapper from 'pages/InvestigationSearchPage/components/NavigationWrapper/NavigationWrapper'
import { UserData } from 'core/hooks/useGetUserData'

interface Props {
  userData: UserData
}

const NotFound: FC<Props> = ({ userData }) => {
  document.title = 'Page not found - Signifyd'

  return (
    <>
      <NavigationWrapper userData={userData} />
      <div style={{ textAlign: 'center' }}>
        <h1>Page Not found</h1>
      </div>
    </>
  )
}

export default NotFound
