import { FC } from 'react'
import CoreBulkActionsNotesModal from './BulkActionsNotesModal'
import { useBulkActionsContext } from './BulkActionsProvider'
import CoreBulkActionsConfirmationModal from './flows/Core/CoreBulkActionsConfirmationModal/CoreBulkActionsConfirmationModal'

const CoreBulkActionFlows: FC = () => {
  const { bulkActionStage } = useBulkActionsContext()

  return (
    <>
      {bulkActionStage === 'NOTES' && <CoreBulkActionsNotesModal />}
      {bulkActionStage === 'CONFIRMATION' && (
        <CoreBulkActionsConfirmationModal />
      )}
    </>
  )
}

export default CoreBulkActionFlows
