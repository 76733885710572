import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Tag } from 'antd'
import useSimilarityParams from 'core/hooks/useSimilarityParams'
import styles from './SimilarityParamTag.less'

const SimilarityParamTag: FC = () => {
  const { t } = useTranslation()
  const similarity = useSimilarityParams()

  if (!similarity?.similarityAttribute) {
    return null
  }

  const { similarityAttribute } = similarity

  return (
    <Tag
      className={styles.tag}
      data-test-id={`similarityTag-${similarityAttribute}`}
    >
      {t('similarity.SIM')}: {t(`similarity.${similarityAttribute}`)}
    </Tag>
  )
}

export default SimilarityParamTag
