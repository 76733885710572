import { FC } from 'react'
import Icon, { DislikeOutlined, FileAddOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { GuaranteeIconBulkActions, Text } from '@signifyd/components'
import { colorAtlantic } from '@signifyd/colors'
import useGetUserData from 'core/hooks/useGetUserData'
import styles from './BulkActionsPopoverContent.less'
import BulkActionsButton from './BulkActionsButton'
import { PRE_AUTH_MERCHANT_IDS } from './data'
import BulkAddToListButton from './buttons/BulkAddToListButton'
import CloseButton from './buttons/CloseButton'
import { useBulkActionsContext } from '../BulkActionsProvider'

const BulkActionsPopoverContent: FC = () => {
  const { t } = useTranslation()

  const { selectedInvestigations, setBulkActionType, setBulkActionStage } =
    useBulkActionsContext()

  const { currentUser, isAdmin } = useGetUserData()

  const canSubmitForGuarantee =
    !isAdmin &&
    currentUser?.customerId &&
    !PRE_AUTH_MERCHANT_IDS.includes(currentUser?.customerId)

  return (
    <>
      <div className={styles.bulkActionsSelected}>
        <Text
          className={styles.bulkActionsSelectedCount}
          data-test-id="selectedCount"
        >
          {selectedInvestigations.length}
        </Text>
        <Text strong size="lg" className={styles.bulkActionsSelectedText}>
          {t('bulkActions.selectedText')}
        </Text>
      </div>
      <div className={styles.bulkActionsSelectedActions}>
        {canSubmitForGuarantee && (
          <BulkActionsButton
            buttonKey="submitGuaranteeButton"
            Icon={
              <Icon
                component={() => (
                  <GuaranteeIconBulkActions fill={colorAtlantic} />
                )}
              />
            }
            onClick={() => {
              setBulkActionType('SUBMIT_FOR_GUARANTEE')
              setBulkActionStage('NOTES')
            }}
          />
        )}
        <BulkActionsButton
          buttonKey="dislikeButton"
          Icon={<DislikeOutlined />}
          onClick={() => {
            setBulkActionType('THUMBS_DOWN')
            setBulkActionStage('NOTES')
          }}
        />
        <BulkActionsButton
          buttonKey="notesButton"
          Icon={<FileAddOutlined />}
          onClick={() => {
            setBulkActionType('ADD_NOTE')
            setBulkActionStage('NOTES')
          }}
        />
        <BulkAddToListButton />
      </div>
      <div className={styles.bulkActionsClose}>
        <CloseButton />
      </div>
    </>
  )
}

export default BulkActionsPopoverContent
