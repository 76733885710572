import { getCaseReviewUrl } from '@signifyd/components'
import { ORDERS_APP_URL } from 'core/Constants'

export interface Props {
  caseId: string
  isCustomer: boolean
}

const getExternalConsoleUrl = ({ caseId, isCustomer }: Props): string => {
  if (isCustomer) {
    return `${ORDERS_APP_URL}/${caseId}`
  }

  return `${getCaseReviewUrl()}/${caseId}`
}

export default getExternalConsoleUrl
