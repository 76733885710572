import { FC, ReactElement } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Popover, Divider } from 'antd'
import { useTranslation, Trans } from 'react-i18next'
import { Text } from '@signifyd/components'
import useGetInvestigationSearchContext from 'core/hooks/useGetInvestigationSearchContext'
import { useTeamCustomerIdQueryParams } from 'pages/InvestigationSearchPage/queries/useTeamCustomerIdQueryParams'
import useGetUserData from 'core/hooks/useGetUserData'
import styles from './SearchHelpHint.less'
import { listItems } from './SearchHelpHintItems'

const HelpOverlay: FC = () => {
  const { t } = useTranslation()
  const { isAdmin } = useGetUserData()
  const params = useTeamCustomerIdQueryParams()
  const { data } = useGetInvestigationSearchContext(params)

  const createItemList = (items: Array<string>): Array<ReactElement> =>
    items.map((item) => {
      return (
        <li key={item}>
          <Trans components={{ bold: <strong /> }}>
            {`searchBar.searchHelp.${item}`}
          </Trans>
        </li>
      )
    })

  const getItemList = (): Array<ReactElement> => {
    if (isAdmin) {
      return createItemList(listItems.admin)
    }

    return createItemList(
      data?.hasAirlineOrders ? listItems.airlines : listItems.physicalGoods
    )
  }

  return (
    <div className={styles.helpHint} data-test-id="searchHelpHintContainer">
      <Text className={styles.title}>{t('searchBar.searchHelp.title')}</Text>
      <Divider className={styles.divider} />
      <div>
        <Text className={styles.label} weight="semibold" size="md">
          {t('searchBar.searchHelp.subtitle')}
        </Text>
        <ul className={styles.helpItems}>{getItemList()}</ul>
      </div>
    </div>
  )
}

const SearchHelpHint: FC = () => (
  <Popover
    content={<HelpOverlay />}
    placement="bottomRight"
    trigger="hover"
    autoAdjustOverflow={false}
    arrow={{ pointAtCenter: true }}
  >
    <QuestionCircleOutlined className={styles.icon} />
  </Popover>
)

export default SearchHelpHint
