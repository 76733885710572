import {
  SEARCH_FIELD,
  RETURN_CHECKPOINT_ACTIONS,
  ORDER_CHANNEL,
  INV_AUTH_GATEWAY_STATUS,
  INV_GUARANTEE_DISPOSITION,
  GUARANTEE_REVIEW_TIMEOUT_DISPOSITION,
  GUARANTEE_REVIEW_DISPOSITION,
  SEARCH_OPERATOR,
  CHECKOUT_CHECKPOINT_ACTIONS,
  CLAIM_STATUS_FILTERS,
  CLAIM_DISPOSITION_FILTERS,
  INTEGRATION_ANALYSIS_ORDER_CHANNEL,
  RECOMMENDED_DECISION_DISPOSITION,
  PROCESSING_POLICY,
} from '@signifyd/http'
import { EnumLike } from 'zod'
import { i18nInstance } from '@signifyd/components'
import { DecodedSearchParams, SearchKeys, isRangeFilter } from '@signifyd/utils'

export interface StringRangeFilter {
  min: string
  max: string
}

interface CheckboxOption {
  label: string
  value: string
}

export type FilterStateValues = string | StringRangeFilter

export enum COL_FILTER_TYPES {
  TEXT = 1,
  NUMBER = 2,
  NUMBER_WITH_RANGE = 3,
  MULTI_SELECT = 4,
}

export enum IS_TEST_INVESTIGATION {
  true = 'true',
  false = 'false',
}

export type FilterValue<T extends COL_FILTER_TYPES> =
  T extends COL_FILTER_TYPES.NUMBER_WITH_RANGE ? StringRangeFilter : string

// Most columns are 1 to 1 with query keys, where filtering on the key will automatically update the query string with the changed value
interface StandardColumnFilter {
  TYPE?: COL_FILTER_TYPES
  canOrderBy?: boolean
  searchKey: SearchKeys
}

// Custom columns get & pass values to the query string via functions, which allows them to be composite
// Custom columns do not support ordering by at this time
export interface CustomColumnFilter {
  TYPE: COL_FILTER_TYPES
  searchKey: 'custom'
  getValuesFromQuery: (query: DecodedSearchParams) => string
  encodeValueForQuery: (filterValue: string) => Partial<DecodedSearchParams>
  dynamicFilterKeys?: Array<SEARCH_FIELD>
  getMultiSelectOptions: (options: Array<string>) => Array<CheckboxOption>
}

export type ColumnFilterData = StandardColumnFilter | CustomColumnFilter

const getNullMultiselectOption = (label: string): CheckboxOption => ({
  label,
  value: SEARCH_OPERATOR.NULL,
})

export const encodeEnumValues = <T extends EnumLike>(
  filterValue: string,
  searchField: T,
  encodeNullValue?: boolean
): Array<T[keyof T]> | undefined => {
  if (!filterValue.length) {
    return undefined
  }

  if (encodeNullValue) {
    searchField = {
      ...searchField,
      NULL: SEARCH_OPERATOR.NULL,
    }
  }

  return Object.values(searchField)
    .map(String)
    .filter((value) => filterValue.split(',').includes(value)) as Array<
    T[keyof T]
  >
}

export const getFilterStateValue = (
  columnData: ColumnFilterData,
  query: DecodedSearchParams
): FilterStateValues => {
  const filterValue =
    columnData.searchKey === 'custom'
      ? columnData.getValuesFromQuery(query)
      : query[columnData.searchKey]

  if (isRangeFilter(filterValue)) {
    const { min, max } = filterValue

    return { min: min?.toString() ?? '', max: max?.toString() ?? '' }
  }

  return filterValue?.toString() ?? ''
}

export const getFilterValueFromArray = (
  query: DecodedSearchParams,
  field: SearchKeys
): string => {
  const value = query[field]

  if (!value || !Array.isArray(value)) {
    return typeof value === 'string' ? value : ''
  }

  return value.filter(Boolean).join(',')
}

export const getQueryFromFilterState = (
  value: FilterStateValues,
  columnData: ColumnFilterData
): Partial<DecodedSearchParams> => {
  const query =
    columnData.searchKey === 'custom'
      ? // TODO FET-1840 https://signifyd.atlassian.net/browse/FET-1840 Remove as cast
        columnData.encodeValueForQuery(value as string)
      : {
          [columnData.searchKey]: value || null,
        }

  return query
}

const getValidOptions = <T extends EnumLike>(
  options: Array<string>,
  allowedEnums: T
): Array<string> => {
  return options.filter((value) => {
    return value.toUpperCase() in allowedEnums
  })
}

// TODO FET-1835 https://signifyd.atlassian.net/browse/FET-1835
// Make the types of this key off of investigation data or something so that this is more strongly typed
export const COL_FILTERS: Record<string, ColumnFilterData> = {
  investigationId: {
    TYPE: COL_FILTER_TYPES.NUMBER,
    searchKey: SEARCH_FIELD.investigationId,
  },
  normalizedPurchaseCreatedAt: {
    TYPE: undefined,
    canOrderBy: true,
    searchKey: SEARCH_FIELD.normalizedPurchaseCreatedAt,
  },
  // NOTE: chargeback and authorizationGatewayStatuses are not column filters
  // The reason to put it here is because they behaves like a column filter on the UI
  // In CaseSearchParams.actions.js, chargeback is mapped to claimsDispositions and claimStatuses param
  chargeback: {
    TYPE: COL_FILTER_TYPES.MULTI_SELECT,
    searchKey: 'custom',
    getValuesFromQuery: (query) => {
      const statuses = [query.claimStatus, query.claimDisposition].flat(1)

      return statuses.filter(Boolean).join(',')
    },
    encodeValueForQuery: (filterValue) => {
      const statuses = encodeEnumValues(filterValue, CLAIM_STATUS_FILTERS)
      const dispositions = encodeEnumValues(
        filterValue,
        CLAIM_DISPOSITION_FILTERS
      )

      return {
        [SEARCH_FIELD.claimStatus]: statuses,
        [SEARCH_FIELD.claimDisposition]: dispositions,
      }
    },
    dynamicFilterKeys: [
      SEARCH_FIELD.claimStatus,
      SEARCH_FIELD.claimDisposition,
    ],
    getMultiSelectOptions: (options) => {
      const validOptions = getValidOptions(options, {
        ...CLAIM_STATUS_FILTERS,
        ...CLAIM_DISPOSITION_FILTERS,
      })

      return validOptions
        .map((field) => ({
          label: i18nInstance.t(
            `dynamicFilters.chargeback.${
              field as CLAIM_STATUS_FILTERS | CLAIM_DISPOSITION_FILTERS
            }`
          ),
          value: field,
        }))
        .concat(
          getNullMultiselectOption(
            i18nInstance.t('dynamicFilters.chargeback.NONE')
          )
        )
    },
  },
  guaranteeDisposition: {
    TYPE: COL_FILTER_TYPES.MULTI_SELECT,
    searchKey: 'custom',
    getValuesFromQuery: (query) =>
      getFilterValueFromArray(query, SEARCH_FIELD.guaranteeDisposition),
    encodeValueForQuery: (filterValue) => {
      const dispositions = encodeEnumValues(
        filterValue,
        INV_GUARANTEE_DISPOSITION,
        true
      )

      return {
        [SEARCH_FIELD.guaranteeDisposition]: dispositions,
      }
    },
    dynamicFilterKeys: [SEARCH_FIELD.guaranteeDisposition],

    getMultiSelectOptions: (options) => {
      const validOptions = getValidOptions(options, INV_GUARANTEE_DISPOSITION)

      return validOptions
        .map((field) => ({
          label: i18nInstance.t(
            `dynamicFilters.guaranteeDisposition.${
              field as keyof typeof INV_GUARANTEE_DISPOSITION
            }`
          ),
          value: field,
        }))
        .concat(
          getNullMultiselectOption(
            i18nInstance.t(`dynamicFilters.guaranteeDisposition.UNREQUESTED`)
          )
        )
    },
  },
  investigationReviewDisposition: {
    TYPE: COL_FILTER_TYPES.MULTI_SELECT,
    searchKey: 'custom',
    getValuesFromQuery: (query) =>
      getFilterValueFromArray(
        query,
        SEARCH_FIELD.investigationReviewDisposition
      ),

    encodeValueForQuery: (filterValue) => {
      const investigationReviewDisposition = encodeEnumValues(
        filterValue,
        GUARANTEE_REVIEW_DISPOSITION
      )

      return {
        [SEARCH_FIELD.investigationReviewDisposition]:
          investigationReviewDisposition,
      }
    },
    dynamicFilterKeys: [SEARCH_FIELD.investigationReviewDisposition],
    getMultiSelectOptions: (options) => {
      const validOptions = getValidOptions(
        options,
        GUARANTEE_REVIEW_DISPOSITION
      )

      return validOptions.map((field) => ({
        label: i18nInstance.t(
          `dynamicFilters.investigationReviewDisposition.${
            field as keyof typeof GUARANTEE_REVIEW_DISPOSITION
          }`
        ),
        value: field,
      }))
    },
  },
  authorizationGatewayStatus: {
    TYPE: COL_FILTER_TYPES.MULTI_SELECT,
    searchKey: 'custom',
    getValuesFromQuery: (query) =>
      getFilterValueFromArray(query, SEARCH_FIELD.authorizationGatewayStatus),

    encodeValueForQuery: (filterValue) => {
      const statuses = encodeEnumValues(filterValue, INV_AUTH_GATEWAY_STATUS)

      return {
        [SEARCH_FIELD.authorizationGatewayStatus]: statuses,
      }
    },
    dynamicFilterKeys: [SEARCH_FIELD.authorizationGatewayStatus],

    getMultiSelectOptions: (options) => {
      const validOptions = getValidOptions(options, INV_AUTH_GATEWAY_STATUS)

      return validOptions
        .map((field) => ({
          label: i18nInstance.t(
            `dynamicFilters.authorizationGatewayStatus.${
              field as INV_AUTH_GATEWAY_STATUS
            }`
          ),
          value: field,
        }))
        .concat(
          getNullMultiselectOption(
            i18nInstance.t(`dynamicFilters.authorizationGatewayStatus.NONE`)
          )
        )
    },
  },
  guaranteeReviewTimeoutDisposition: {
    TYPE: COL_FILTER_TYPES.MULTI_SELECT,
    searchKey: 'custom',
    getValuesFromQuery: (query) =>
      getFilterValueFromArray(
        query,
        SEARCH_FIELD.guaranteeReviewTimeoutDisposition
      ),

    encodeValueForQuery: (filterValue) => {
      const guaranteeReviewTimeoutDispositions = encodeEnumValues(
        filterValue,
        GUARANTEE_REVIEW_TIMEOUT_DISPOSITION
      )

      return {
        [SEARCH_FIELD.guaranteeReviewTimeoutDisposition]:
          guaranteeReviewTimeoutDispositions,
      }
    },
    dynamicFilterKeys: [SEARCH_FIELD.guaranteeReviewTimeoutDisposition],
    getMultiSelectOptions: (options) => {
      const validOptions = getValidOptions(
        options,
        GUARANTEE_REVIEW_TIMEOUT_DISPOSITION
      )

      return validOptions.map((field) => ({
        label: i18nInstance.t(
          `dynamicFilters.guaranteeReviewTimeoutDisposition.${
            field as keyof typeof GUARANTEE_REVIEW_TIMEOUT_DISPOSITION
          }`
        ),
        value: field,
      }))
    },
  },
  returnDecisionCheckpointAction: {
    TYPE: COL_FILTER_TYPES.MULTI_SELECT,
    searchKey: 'custom',
    getValuesFromQuery: (query) =>
      getFilterValueFromArray(
        query,
        SEARCH_FIELD.returnDecisionCheckpointAction
      ),

    encodeValueForQuery: (filterValue) => {
      const returnDecisionCheckpointAction = encodeEnumValues(
        filterValue,
        RETURN_CHECKPOINT_ACTIONS
      )

      return {
        [SEARCH_FIELD.returnDecisionCheckpointAction]:
          returnDecisionCheckpointAction,
      }
    },
    dynamicFilterKeys: [SEARCH_FIELD.returnDecisionCheckpointAction],
    getMultiSelectOptions: (options) => {
      const validOptions = getValidOptions(options, RETURN_CHECKPOINT_ACTIONS)

      return validOptions.map((field) => ({
        label: i18nInstance.t(
          `dynamicFilters.returnDecisionCheckpointAction.${
            field as keyof typeof RETURN_CHECKPOINT_ACTIONS
          }`
        ),
        value: field,
      }))
    },
  },
  orderChannel: {
    TYPE: COL_FILTER_TYPES.MULTI_SELECT,
    searchKey: 'custom',
    getValuesFromQuery: (query) => {
      return getFilterValueFromArray(
        query,
        SEARCH_FIELD.orderChannel
      ).toLowerCase()
    },
    encodeValueForQuery: (filterValue) => {
      // Mismatch between casing on order channels
      const orderChannels = Object.values(ORDER_CHANNEL).filter((channel) => {
        return filterValue.split(',').includes(channel.toLowerCase())
      })

      return {
        [SEARCH_FIELD.orderChannel]:
          // TODO FET-1885: Remove / merge INTEGRATION_ANALYSIS types from sig-http and kill this cast
          // https://signifyd.atlassian.net/browse/FET-1885
          orderChannels as unknown as Array<INTEGRATION_ANALYSIS_ORDER_CHANNEL>,
      }
    },
    dynamicFilterKeys: [SEARCH_FIELD.orderChannel],
    getMultiSelectOptions: (options) => {
      const validOptions = getValidOptions(options, ORDER_CHANNEL)

      return validOptions.map((field) => ({
        label: i18nInstance.t(
          `dynamicFilters.orderChannel.${
            field.toUpperCase() as keyof typeof ORDER_CHANNEL
          }`
        ),
        value: field,
      }))
    },
  },

  checkoutPolicyAction: {
    TYPE: COL_FILTER_TYPES.MULTI_SELECT,
    searchKey: 'custom',
    getValuesFromQuery: ({ checkout }) => {
      if (checkout) {
        return checkout.join(',')
      }

      return ''
    },
    encodeValueForQuery: (filterValue) => {
      const checkout = encodeEnumValues(
        filterValue,
        CHECKOUT_CHECKPOINT_ACTIONS,
        true
      )

      return {
        [SEARCH_FIELD.checkout]: checkout,
      }
    },
    dynamicFilterKeys: [
      SEARCH_FIELD.recommendedAction,
      SEARCH_FIELD.guaranteeRecommendedAction,
    ],

    getMultiSelectOptions: (options) => {
      const validOptions = getValidOptions(options, CHECKOUT_CHECKPOINT_ACTIONS)

      return validOptions.map((field) => ({
        label: i18nInstance.t(
          `dynamicFilters.recommendedAction.${
            field.toUpperCase() as keyof typeof CHECKOUT_CHECKPOINT_ACTIONS
          }`
        ),
        value: field.toUpperCase(),
      }))
    },
  },
  recommendedDecisionDisposition: {
    TYPE: COL_FILTER_TYPES.MULTI_SELECT,
    searchKey: 'custom',
    getValuesFromQuery: ({ recommendedDecisionDisposition }) => {
      if (recommendedDecisionDisposition) {
        return recommendedDecisionDisposition.join(',')
      }

      return ''
    },
    encodeValueForQuery: (filterValue) => {
      const recommendedDecisionDisposition = encodeEnumValues(
        filterValue,
        RECOMMENDED_DECISION_DISPOSITION,
        true
      )

      return {
        [SEARCH_FIELD.recommendedDecisionDisposition]:
          recommendedDecisionDisposition,
      }
    },
    dynamicFilterKeys: [SEARCH_FIELD.recommendedDecisionDisposition],

    getMultiSelectOptions: (options) => {
      const validOptions = getValidOptions(
        options,
        RECOMMENDED_DECISION_DISPOSITION
      )

      return validOptions
        .map((field) => ({
          label: i18nInstance.t(
            `dynamicFilters.recommendedDecisionDisposition.${
              field.toUpperCase() as keyof typeof RECOMMENDED_DECISION_DISPOSITION
            }`
          ),
          value: field.toUpperCase(),
        }))
        .concat(
          getNullMultiselectOption(
            i18nInstance.t(`dynamicFilters.recommendedDecisionDisposition.NONE`)
          )
        )
    },
  },
  cardHolderName: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.cardHolderName,
  },
  recipientFullName: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.recipientFullName,
  },
  userAccountEmail: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.userAccountEmail,
  },
  recipientConfirmationEmail: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.recipientConfirmationEmail,
  },
  billingAddressFullAddress: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.billingAddress,
  },
  recipientAddress: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.recipientAddress,
  },
  userAccountPhone: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.userAccountPhone,
  },
  recipientConfirmationPhone: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.recipientConfirmationPhone,
  },
  productName: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.productName,
  },
  shippingMethod: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.shippingMethod,
  },
  cardBin: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.cardBin,
  },
  cardBinCountry: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.cardBinCountry,
  },
  billingAddressProvinceCode: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.billingAddressProvinceCode,
  },
  cardLastFourDigits: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.cardLastFourDigits,
  },
  browserIpAddress: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.browserIp,
  },
  threatMetrixDeviceId: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.deviceId,
  },
  orderTotalAmount: {
    TYPE: COL_FILTER_TYPES.NUMBER_WITH_RANGE,
    canOrderBy: true,
    searchKey: SEARCH_FIELD.orderTotalAmount,
  },
  userAccountNumber: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.userAccountNumber,
  },
  score: {
    TYPE: COL_FILTER_TYPES.NUMBER_WITH_RANGE,
    canOrderBy: true,
    searchKey: SEARCH_FIELD.signifydScore,
  },
  orderExternalId: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.orderId,
  },
  authorizationFailureReason: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.gatewayErrorCode,
  },
  discountCode: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.discountCode,
  },
  accountHolderTaxId: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.accountHolderTaxId,
  },
  avsResponseCode: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.avsResponseCode,
  },
  cvvResponseCode: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.cvvResponseCode,
  },
  ipGeolocation: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.ipGeolocationFull,
  },
  guaranteeAutoDecisionReason: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.guaranteeAutoDecisionReason,
  },
  isTestInvestigation: {
    TYPE: COL_FILTER_TYPES.MULTI_SELECT,
    searchKey: 'custom',
    getValuesFromQuery: (query) =>
      getFilterValueFromArray(query, SEARCH_FIELD.isTestInvestigation),

    encodeValueForQuery: (filterValue) => {
      return {
        [SEARCH_FIELD.isTestInvestigation]: filterValue
          ? [filterValue]
          : undefined,
      }
    },
    dynamicFilterKeys: [SEARCH_FIELD.isTestInvestigation],

    getMultiSelectOptions: (options) => {
      return options.map((field) => ({
        label: i18nInstance.t(
          `dynamicFilters.isTestInvestigation.${field as IS_TEST_INVESTIGATION}`
        ),
        value: field,
      }))
    },
  },
  threat_metrix_device_id: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.deviceId,
  },
  sellerId: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.sellerId,
  },
  parentEntity: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.sellerParentEntity,
  },
  sellerName: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.sellerName,
  },
  processingPolicy: {
    TYPE: COL_FILTER_TYPES.MULTI_SELECT,
    searchKey: 'custom',
    getValuesFromQuery: (query) =>
      getFilterValueFromArray(query, SEARCH_FIELD.processingPolicy),

    encodeValueForQuery: (filterValue) => {
      const processingPolicies = encodeEnumValues(
        filterValue,
        PROCESSING_POLICY
      )

      return {
        [SEARCH_FIELD.processingPolicy]: processingPolicies,
      }
    },
    dynamicFilterKeys: [SEARCH_FIELD.processingPolicy],
    getMultiSelectOptions: (options) => {
      const validOptions = getValidOptions(options, PROCESSING_POLICY)

      return validOptions.map((field) => ({
        label: i18nInstance.t(
          `dynamicFilters.processingPolicy.${
            field as keyof typeof PROCESSING_POLICY
          }`
        ),
        value: field.toUpperCase(),
      }))
    },
  },
  paymentMethod: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.paymentMethod,
  },
  transactionId: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.transactionId,
  },
  bindbBank: {
    TYPE: COL_FILTER_TYPES.TEXT,
    searchKey: SEARCH_FIELD.bindbBank,
  },
}

export type ColFilterKey = keyof typeof COL_FILTERS
export type ColFilters = Partial<{
  [key in ColFilterKey]: string
}>
