import { message } from 'antd'
import { useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FILTER_JOIN_TYPE } from '@signifyd/utils'
import useGetInvestigations from 'pages/InvestigationSearchPage/containers/SearchResultsTable/useGetInvestigations'

const useNotifyOnRefresh = (joinType?: FILTER_JOIN_TYPE | null): void => {
  const { t } = useTranslation()

  const { isFetching, isError } = useGetInvestigations()
  const previousJoinType = useRef(joinType)

  useEffect(() => {
    if (isFetching || joinType === previousJoinType.current) {
      return
    }

    if (!joinType) {
      return
    }

    if (isError) {
      message.error(
        t(
          `investigationSearch.searchTypeSwitch.notification.failure.${joinType}`
        )
      )
    } else {
      message.success({
        content: t(
          `investigationSearch.searchTypeSwitch.notification.success.${joinType}`
        ),
        key: 'searchTypeSwitchMessage',
        duration: 5,
      })
    }

    previousJoinType.current = joinType
  }, [joinType, isFetching, isError, t])
}

export default useNotifyOnRefresh
