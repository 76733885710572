import { Text, i18nInstance } from '@signifyd/components'
import { FC } from 'react'
import useGetInvestigations from 'pages/InvestigationSearchPage/containers/SearchResultsTable/useGetInvestigations'
import { useTablePagination } from 'pages/InvestigationSearchPage/queries/useTablePagination'

const getResultsText = (min: number, max: number, total: number): string => {
  if (!total) {
    return i18nInstance.t('investigationSearch.searchResults.noCases')
  }

  if (total === 1) {
    return i18nInstance.t('investigationSearch.searchResults.oneCase')
  }

  return i18nInstance.t('investigationSearch.searchResults.multipleCases', {
    min,
    max,
    total,
  })
}

const SearchResults: FC = () => {
  const {
    query: { current, pageSize },
  } = useTablePagination()

  const { data } = useGetInvestigations()
  const total = data?.totalResults ?? 0

  const min = (current - 1) * pageSize + 1
  const max = Math.min(current * pageSize, total)

  const resultsText = getResultsText(min, max, total)

  return (
    <>
      <Text size="md" data-test-id="searchResults">
        {resultsText}
      </Text>
    </>
  )
}

export default SearchResults
