import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Space,
  Text,
  DeviceIdIcon,
  EmailIcon,
  IpAddressIcon,
  PhoneNumberIcon,
  AddressIcon,
  IconProps,
  TaxIdIcon,
  AccountNumberIcon,
} from '@signifyd/components'
import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import GridSelect from 'core/components/GridSelect'
import { RadioButtonItem } from 'core/components/GridSelect/GridSelect'
import styles from './SelectListTypeModalContent.less'

interface Props {
  listTypes: Array<PREDICTION_LIST_TYPE>
  onChange: (listType: PREDICTION_LIST_TYPE) => void
  selectedListType?: PREDICTION_LIST_TYPE
}

type IconComponents = Record<string, FC<IconProps>>

const icons: IconComponents = {
  [PREDICTION_LIST_TYPE.DEVICE_ID]: DeviceIdIcon,
  [PREDICTION_LIST_TYPE.EMAIL]: EmailIcon,
  [PREDICTION_LIST_TYPE.IP_ADDRESS]: IpAddressIcon,
  [PREDICTION_LIST_TYPE.PHONE_NUMBER]: PhoneNumberIcon,
  [PREDICTION_LIST_TYPE.ADDRESS]: AddressIcon,
  [PREDICTION_LIST_TYPE.ACCOUNT_NUMBER]: AccountNumberIcon,
  [PREDICTION_LIST_TYPE.TAX_ID]: TaxIdIcon,
}

const acceptedListTypes = Object.keys(icons) as Array<PREDICTION_LIST_TYPE>

const SelectListTypeModalContent: FC<Props> = ({
  listTypes,
  onChange,
  selectedListType,
}) => {
  const { t } = useTranslation()

  const listTypeItems: Array<RadioButtonItem> = acceptedListTypes
    .filter((acceptedListType) => listTypes.includes(acceptedListType))
    .map((listType) => {
      return {
        key: PREDICTION_LIST_TYPE[listType],
        label: t(
          `bulkActions.bulkAddToList.addToListModal.listTypeStartCase.${listType}`
        ),
        icon: icons[listType],
      }
    })

  return (
    <>
      <div className={styles.helpText}>
        <Text className={styles.modalDescription} size="md">
          {t('bulkActions.bulkAddToList.addToListModal.description')}
        </Text>
        <Tooltip
          title={t('bulkActions.bulkAddToList.addToListModal.tooltip')}
          placement="topLeft"
        >
          <QuestionCircleOutlined className={styles.questionIcon} />
        </Tooltip>
      </div>

      <Space size="md" />
      <GridSelect
        items={listTypeItems}
        span={8}
        value={selectedListType}
        onChange={onChange}
      />
    </>
  )
}

export default SelectListTypeModalContent
