import { FC } from 'react'
import { INV_GUARANTEE_DISPOSITION } from '@signifyd/http'
import styles from './GuaranteeDispositionCell.less'
import CustomerDispositionTag from './CustomerDispositionTag'
import AdminDispositionIcon from './AdminDispositionTag'

interface Props {
  text?: INV_GUARANTEE_DISPOSITION
  isDecidedByMR: boolean
  isCustomer: boolean
}

const GuaranteeDispositionCell: FC<Props> = ({
  text,
  isDecidedByMR,
  isCustomer,
}) => {
  if (!text) {
    return null
  }

  return (
    <div className={styles.tag}>
      {isCustomer ? (
        <CustomerDispositionTag text={text} />
      ) : (
        <AdminDispositionIcon text={text} isDecidedByMR={isDecidedByMR} />
      )}
    </div>
  )
}

export default GuaranteeDispositionCell
