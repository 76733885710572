export default {
  error: 'There was a problem with your filters. Please try again.',
  noFiltersFound: 'No filters found',
  chargeback: {
    APPROVED: 'Approved',
    CANCELLED: 'Canceled',
    CLOSED: 'Closed',
    DECLINED: 'Declined ',
    NEED_MORE_INFO: 'Need more info',
    ON_HOLD: 'On hold',
    PENDING: 'Pending',
    NONE: 'None',
    PENDING_DOCS: 'Pending docs',
    IN_REVIEW: 'In review',
    NULL: 'None',
  },
  guaranteeDisposition: {
    APPROVED: 'Approved',
    CANCELED: 'Canceled',
    CLOSED: 'Closed',
    DECLINED: 'Declined',
    IN_REVIEW: 'In review',
    PENDING: 'Pending',
    PROCESSING: 'Processing',
    UNREQUESTED: 'Unrequested',
    ESCALATED: 'Escalated',
    CANCELLED: 'Canceled',
    PROCESSING_REGUARANTEE: 'Processing re-guarantee',
    UNDEFINED_DISPOSITION: 'Undefined disposition',
  },
  investigationReviewDisposition: {
    FRAUDULENT: 'Bad',
    GOOD: 'Good',
    UNSET: 'None',
  },
  isTestInvestigation: {
    true: 'Case is test',
    false: 'Case is not test',
  },
  guaranteeReviewTimeoutDisposition: {
    APPROVED: 'Approved',
    DECLINED: 'Declined',
    IN_REVIEW: 'In review',
  },
  returnDecisionCheckpointAction: {
    ACCEPT: 'Accept',
    REJECT: 'Reject',
    HOLD: 'Hold',
    CREDIT: 'Credit',
  },
  orderChannel: {
    IN_STORE_KIOSK: 'In store kiosk',
    MARKETPLACE: 'Marketplace',
    MOBILE_APP: 'Mobile app',
    OFFLINE: 'Offline',
    PHONE: 'Phone',
    POS: 'POS',
    SCAN_AND_GO: 'Scan & Go',
    SOCIAL: 'Social',
    WEB: 'Web',
  },
  recommendedDecisionDisposition: {
    APPROVED: 'Accept',
    PENDING: 'Hold',
    DECLINED: 'Reject',
    NONE: 'None',
  },
  recommendedAction: {
    ACCEPT: 'Accept',
    HOLD: 'Hold',
    REJECT: 'Reject',
    NONE: 'None',
  },
  authorizationGatewayStatus: {
    ERROR: 'Error',
    PENDING: 'Pending',
    SUCCESS: 'Success',
    FAILURE: 'Failure',
    NONE: 'None',
    NULL: 'None',
  },
  customerSelect: {
    capitalizedOne: 'Customer',
    capitalizedOther: 'Customers',
    one: 'customer',
    other: 'customers',
  },
  teamSelect: {
    capitalizedOne: 'Team',
    capitalizedOther: 'Teams',
    one: 'team',
    other: 'teams',
  },
  processingPolicy: {
    PRE_AUTH: 'PRE_AUTH',
    POST_AUTH: 'POST_AUTH',
    GENERAL_SYNC: 'GENERAL_SYNC',
    HISTORICAL: 'HISTORICAL',
    TESTING: 'TESTING',
    PRE_AUTH_INTERNAL: 'PRE_AUTH_INTERNAL',
    HISTORICAL_POST_AUTH: 'HISTORICAL_POST_AUTH',
    HISTORICAL_PRE_AUTH: 'HISTORICAL_PRE_AUTH',
    ASYNC_UPDATES: 'ASYNC_UPDATES',
    UNRECOGNIZED: 'UNRECOGNIZED',
  },
}
