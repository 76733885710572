import {
  ExternalLink,
  getDecisionCenterUrl,
  i18nInstance,
  Text,
} from '@signifyd/components'
import { Return } from '@signifyd/http'
import { FC } from 'react'
import { Flex, Popover } from 'antd'
import ReturnCheckpointActionTag from './ReturnCheckpointActionTag'
import MultiComponentPopoverButton from '../MultiComponentPopover/MultiComponentPopoverButton'

interface ReturnLabelProps {
  returnPolicyName: string
  returnPolicyId?: number | null
}

const ReturnLabel: FC<ReturnLabelProps> = ({
  returnPolicyName,
  returnPolicyId,
}) => {
  if (!returnPolicyId) {
    return <Text>{returnPolicyName}</Text>
  }

  return (
    <ExternalLink
      url={`${getDecisionCenterUrl()}/policies/summary/${returnPolicyId}`}
    >
      {returnPolicyName}
    </ExternalLink>
  )
}

interface Props {
  returns: Array<Return>
}

const ReturnCheckpointNameCell: FC<Props> = ({ returns }) => {
  const returnsToShow = returns.filter(
    ({ returnPolicyName }) => !!returnPolicyName
  )

  if (!returnsToShow.length) {
    return null
  }

  const [firstItem] = returnsToShow

  const PopoverItems = returnsToShow.map(
    ({ returnPolicyName, returnPolicyId, decision, returnId }) => {
      return (
        <Flex key={returnId} gap={8} align="center">
          <div>
            {decision && <ReturnCheckpointActionTag decision={decision} />}
          </div>
          <div>
            <ReturnLabel
              returnPolicyName={returnPolicyName!}
              returnPolicyId={returnPolicyId}
            />
          </div>
        </Flex>
      )
    }
  )

  return (
    <div>
      <ReturnLabel
        returnPolicyName={firstItem.returnPolicyName!}
        returnPolicyId={firstItem.returnPolicyId}
      />
      {PopoverItems.length > 1 && (
        <span>
          <Popover
            placement="topLeft"
            title={i18nInstance.t(
              'investigationSearch.column.returnPolicyAction'
            )}
            trigger="hover"
            content={
              <Flex gap={5} vertical>
                {PopoverItems}
              </Flex>
            }
          >
            <span>
              <MultiComponentPopoverButton amount={PopoverItems.length - 1} />
            </span>
          </Popover>
        </span>
      )}
    </div>
  )
}

export default ReturnCheckpointNameCell
