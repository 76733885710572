export default {
  placeholder: 'Search cases',
  resetSearch: 'Clear search',
  searchTermsExceededError:
    'Maximum number of searchable terms exceeded. Reduce selection to 1000 or less.',
  date: {
    today: 'Today',
    lastSevenDays: 'Last 7 days',
    lastThirtyDays: 'Last 30 days',
  },
  searchHelp: {
    title: 'Search help',
    subtitle:
      'To search by any of the following fields enter the value you are searching for:',
    cardholderName: 'Cardholder name',
    externalOrderId: 'External order ID',
    caseId: 'Investigation / Case ID',
    orderTotalAmount: 'Order total amount',
    platformOrderId: 'Platform order ID',
    recipientFullName: 'Recipient full name',
    recipientConfirmationEmailAddress: 'Recipient confirmation email address',
    recipientConfirmationPhone: 'Recipient confirmation phone',
    transactionId: 'Transaction ID',
    userAccountEmailAddress: 'User account email address',
    userAccountPhoneNumber: 'User account phone number',
    userAccountUsername: 'User account username',
    checkoutToken: 'Checkout token',
    sellerId: 'Seller ID',
    parentEntity: 'Parent entity',
    sellerName: 'Seller name',
    passengerNameRecord: 'Record locator',
    leadPassenger: 'Lead passenger',
  },
}
