import { PREDICTION_LIST_TYPE } from '@signifyd/http'

const listTypeStartCase: Record<
  PREDICTION_LIST_TYPE | `${PREDICTION_LIST_TYPE}_other`,
  string
> = {
  EMAIL: 'Email',
  EMAIL_other: 'Emails',
  CARD_BIN: 'Credit card BIN',
  CARD_BIN_other: 'Credit card BINs',
  IP_ADDRESS: 'IP address',
  IP_ADDRESS_other: 'IP addresses',
  DEVICE_ID: 'Device ID',
  DEVICE_ID_other: 'Device IDs',
  ITEM_ID: 'Item ID',
  ITEM_ID_other: 'Item IDs',
  DISCOUNT_CODE: 'Discount code',
  DISCOUNT_CODE_other: 'Discount codes',
  PHONE_NUMBER: 'Phone number',
  PHONE_NUMBER_other: 'Phone numbers',
  ADDRESS: 'Address',
  ADDRESS_other: 'Addresses',
  PROMO_ABUSE: 'Promo abuse',
  PROMO_ABUSE_other: 'Promo abuses',
  RESELLER_ABUSE: 'Reseller abuse',
  RESELLER_ABUSE_other: 'Reseller abuses',
  MEMBERSHIP_ID: 'Membership ID',
  MEMBERSHIP_ID_other: 'Membership IDs',
  ACCOUNT_NUMBER: 'Account number',
  ACCOUNT_NUMBER_other: 'Account numbers',
  TAX_ID: 'Tax ID',
  TAX_ID_other: 'Tax IDs',
  EMAIL_DOMAIN: 'Email domain',
  EMAIL_DOMAIN_other: 'Email domains',
}

export default {
  fetchListsFailure: 'Failure to fetch lists',
  dataSourceFields: {
    CONFIRMATION_EMAIL: 'confirmation email address',
    CONFIRMATION_EMAIL_other: 'confirmation email addresses',
    ACCOUNT_EMAIL: 'account email address',
    ACCOUNT_EMAIL_other: 'account email addresses',
    DELIVERY_ADDRESS: 'delivery address',
    DELIVERY_ADDRESS_other: 'delivery addresses',
    BILLING_ADDRESS: 'billing address',
    BILLING_ADDRESS_other: 'billing addresses',
  },
  addToListFailure: 'Failure to add to list',
  duplicatesModal: {
    title: 'Resolve duplicates',
    warning: 'Duplicate items found. Please resolve before continuing.',
    description:
      'Submit to confirm this action for all orders summarized in the table below.',
    discardLabel: 'Discard duplicates (recommended)',
    discardDescription:
      'Duplicated items will be discarded and items not already in list added',
    overwriteLabel: 'Keep duplicates and overwrite existing',
    overwriteDescription:
      'Items already in list will be overwritten including Date added and Added by',
    backButton: 'Back',
    submitButton: 'Submit',
  },
  addToListModal: {
    title: 'Add to list',
    description: 'Select a list type to add to:',
    tooltip:
      'Lists are used in conjunction with Decision Center policies to impact orders. The type of list is part of a condition used in a policy configuration.',
    continueButton: 'Continue',
    listTypeStartCase,
  },
  selectListModal: {
    title: 'Select a list',
    description: 'Select which list you would like to add items to:',
    items_one: 'item',
    items_other: 'items',
    selectListPlaceholder: 'Select a list',
    continueButton: 'Continue',
    backButton: 'Back',
  },
  dataSourceSelectionModal: {
    title: 'Select {{ datasource }} type',
    description:
      'Select which type of {{ datasource }} you would like to add to list:',
    cancelButton: 'Back',
    continueButton: 'Continue',
    confirmationEmail: 'Confirmation email',
    accountEmail: 'Account email',
    deliveryAddress: 'Delivery address',
    billingAddress: 'Billing address',
  },
  addToListConfirmationModal: {
    disabledInvestigationReason: 'No {{ listType }} found for this order',
  },
  modal: {
    backButton: 'Back',
  },
}
