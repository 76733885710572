import { RefObject, useContext } from 'react'
import { SearchResultsResponse } from 'pages/InvestigationSearchPage/hooks/useGetSearchResults'
import { tableContext } from '../SearchContainer/TableProvider'
import useGetColumns from './useGetColumns'

const useTableScroll = (
  data: SearchResultsResponse | undefined
): {
  tableContainer: RefObject<HTMLDivElement>
  scroll?: {
    x: number
    y: number
  }
} => {
  const { tableContainer } = useContext(tableContext)
  const columns = useGetColumns()

  if (!data) {
    return {
      tableContainer,
      // If you return a scroll object before there is data in the table, resize observer callbacks will spawn and crash our cypress tests (as of 4/16/24)
      scroll: undefined,
    }
  }

  // TODO FET-1842 https://signifyd.atlassian.net/browse/FET-1842
  // Replace this with a better approach not using querySelector
  const getTableBodyHeight = (): number => {
    if (tableContainer.current) {
      const thead = tableContainer.current.querySelector(
        '.searchResultsTable .ant-table-thead'
      )

      if (!thead) {
        return 0
      }

      // Must exclude table head height for Table's scroll prop
      return tableContainer.current.clientHeight - thead.clientHeight
    }

    return 0
  }

  const scrollWidth = columns.reduce(
    (total, column) => total + Number(column.width ?? 0),
    0
  )
  const scroll = {
    x: scrollWidth,
    y: getTableBodyHeight(),
  }

  return {
    tableContainer,
    scroll,
  }
}

export default useTableScroll
