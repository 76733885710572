import useGetUserData from 'core/hooks/useGetUserData'
import useGetInvestigationSearchContext from 'core/hooks/useGetInvestigationSearchContext'
import { useTeamCustomerIdQueryParams } from 'pages/InvestigationSearchPage/queries/useTeamCustomerIdQueryParams'
import getColumns, { SearchTableColumnProps } from './ColumnConfig'

const useGetColumns = (): Array<SearchTableColumnProps> => {
  const params = useTeamCustomerIdQueryParams()
  const userData = useGetUserData()
  const { data: userColumns } = useGetInvestigationSearchContext(params)

  if (!userData || !userColumns) {
    return []
  }

  const { isTraining, isAdmin, currentUser } = userData
  const columns = getColumns({
    isTraining,
    isCustomer: !isAdmin,
    isRulesBuilderUser: !!currentUser?.features.RULES_BUILDER,
  })

  const filterColumns = columns.filter((column) =>
    userColumns.availableFields.includes(column.contextMatches)
  )

  return filterColumns
}

export default useGetColumns
