import {
  getInvestigationSearchContext,
  SearchContextRequest,
} from '@signifyd/http'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import useGetUserData from './useGetUserData'

interface SearchContextResponse {
  availableFields: Array<string>
  hasAirlineOrders: boolean
}

const useGetInvestigationSearchContext = (
  params: SearchContextRequest
): UseQueryResult<SearchContextResponse> => {
  const { isAdmin } = useGetUserData()

  return useQuery<SearchContextResponse>(
    ['searchContext', params, isAdmin],
    async () => {
      const {
        data: { availableFields },
      } = await getInvestigationSearchContext(params)

      const hasAirlineOrders = availableFields.some(
        (column: string) =>
          column === 'recordLocator' || column === 'leadPassengerName'
      )

      return {
        availableFields,
        hasAirlineOrders: !isAdmin ? hasAirlineOrders : false,
      }
    }
  )
}

export default useGetInvestigationSearchContext
