import { FC } from 'react'
import { AdminNavBar, CustomerNavBar } from '@signifyd/components'
import { UserData } from 'core/hooks/useGetUserData'
import useUpdateUiState from './useUpdateUiState'

interface Props {
  userData: UserData
}

const NavigationWrapper: FC<Props> = ({ userData }) => {
  const { currentUser, userConfigs } = userData
  const updateUiState = useUpdateUiState(currentUser)

  if (currentUser.isAdmin) {
    return <AdminNavBar user={currentUser} userConfigs={userConfigs} />
  }

  return (
    <CustomerNavBar
      user={currentUser}
      userConfigs={userConfigs}
      onLanguageSelectionChange={async (selectedLocale) => {
        await updateUiState.mutateAsync({ locale: selectedLocale })
      }}
    />
  )
}

export default NavigationWrapper
