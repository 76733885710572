import { FC } from 'react'
import { ExternalLink } from '@signifyd/components'
import getExternalConsoleUrl from 'core/utils/getExternalConsoleUrl'

interface Props {
  caseId: string
  isCustomer: boolean
}

const InvestigationIdCell: FC<Props> = ({ caseId, isCustomer }) => {
  const url = getExternalConsoleUrl({ caseId, isCustomer })

  return (
    <ExternalLink data-test-id={`searchLink${caseId}`} url={url}>
      {caseId}
    </ExternalLink>
  )
}

export default InvestigationIdCell
