import { FC } from 'react'
import { Unauthorized, useAppCues } from '@signifyd/components'
import { Routes, Route, Navigate, useLocation } from 'react-router'
import NotFound from 'pages/NotFound'
import InvestigationSearchPage from 'pages/InvestigationSearchPage/index'
import NavigationWrapper from 'pages/InvestigationSearchPage/components/NavigationWrapper/NavigationWrapper'

import { UserData } from 'core/hooks/useGetUserData'
import useGetUserBulkActionsPermission from 'core/hooks/useGetUserBulkActionsPermission'

const defaultRoute = '/casesearch'

interface Props {
  userData: UserData
}

const AppRoutes: FC<Props> = ({ userData }) => {
  const features = []
  const { hasAccess, currentUser } = userData
  const { data: canPerformBulkAction } = useGetUserBulkActionsPermission()

  if (canPerformBulkAction) {
    features.push('bulkActions')
  }

  useAppCues({
    user: currentUser,
    appName: 'case-search',
    allowedBuildEnvs: window.isRegressionTest ? [] : ['staging', 'production'],
    buildEnv: process.env.BUILD_ENV,
    features,
  })

  const { search } = useLocation()

  if (!hasAccess) {
    return (
      <>
        <NavigationWrapper userData={userData} />
        <Unauthorized />
      </>
    )
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to={`${defaultRoute}${search}`} />} />
      <Route
        path={defaultRoute}
        element={<InvestigationSearchPage userData={userData} />}
      />
      <Route path="*" element={<NotFound userData={userData} />} />
    </Routes>
  )
}

export default AppRoutes
