import {
  InvestigationInfo,
  PredictionListTypeAddToList,
  PREDICTION_LIST_TYPE,
  PhysicalAddress,
} from '@signifyd/http'
import {
  getBillingAddress,
  getConfirmationEmail,
  getConfirmationPhone,
  getDeliveryAddress,
} from 'pages/InvestigationSearchPage/containers/SearchResultsTable/ColumnConfig.dataHelpers'
import { EMAIL_TYPE, ADDRESS_TYPE, DataSource } from '../../../types'

const getRawAddress = (address?: PhysicalAddress): string | undefined => {
  if (!address) {
    return undefined
  }

  const { streetAddress, unit, city, provinceCode, postalCode, countryCode } =
    address

  return [streetAddress, unit, city, provinceCode, postalCode, countryCode]
    .filter(Boolean)
    .join(' ')
}

export const getInvestigationValue = (
  investigation: InvestigationInfo,
  listType: PredictionListTypeAddToList,
  dataSource?: DataSource
): string | undefined => {
  switch (listType) {
    case PREDICTION_LIST_TYPE.ADDRESS:
      if (dataSource === ADDRESS_TYPE.BILLING_ADDRESS) {
        return getRawAddress(getBillingAddress(investigation))
      }

      return getRawAddress(getDeliveryAddress(investigation))

    case PREDICTION_LIST_TYPE.EMAIL:
      if (dataSource === EMAIL_TYPE.ACCOUNT_EMAIL) {
        return investigation.userAccountEmail
      }

      return getConfirmationEmail(investigation)

    case PREDICTION_LIST_TYPE.IP_ADDRESS:
      return investigation.browserIpAddress

    case PREDICTION_LIST_TYPE.PHONE_NUMBER:
      return getConfirmationPhone(investigation)

    case PREDICTION_LIST_TYPE.DEVICE_ID:
      return investigation.threatMetrixDeviceId

    case PREDICTION_LIST_TYPE.TAX_ID:
      return investigation.accountHolderTaxId

    case PREDICTION_LIST_TYPE.ACCOUNT_NUMBER:
      return investigation.userAccountNumber

    default:
      return undefined
  }
}
