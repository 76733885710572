import { InvestigationInfo, PhysicalAddress } from '@signifyd/http'

export const getBillingAddress = (
  investigation: InvestigationInfo
): PhysicalAddress | undefined => {
  return investigation.billingAddress
}

export const getDeliveryAddress = (
  investigation: InvestigationInfo
): PhysicalAddress | undefined => {
  return investigation.recipients?.[0]?.address
}

export const getAccountEmail = (
  investigation: InvestigationInfo
): string | undefined => {
  return investigation.userAccountEmail
}

export const getConfirmationEmail = (
  investigation: InvestigationInfo
): string | undefined => {
  return investigation.recipients?.[0]?.confirmationEmail ?? undefined
}

export const getConfirmationPhone = (
  investigation: InvestigationInfo
): string | undefined => {
  return investigation.recipients?.[0]?.confirmationPhone ?? undefined
}
