import { PredictionListTypeAddToList } from '@signifyd/http'
import { useEffect, useState } from 'react'
import { Key } from 'antd/lib/table/interface'
import {
  EvaluatedInvestigation,
  InvestigationEvaluation,
} from 'pages/InvestigationSearchPage/hooks/useGetEvaluatedInvestigations'
import { useBulkActionsContext } from '../../../BulkActionsProvider'
import { DataSource } from '../../../types'
import getAddToListInvestigationEvaluation from './getAddToListInvestigationEvaluation'

export interface AddToListEvaluatedInvestigation
  extends EvaluatedInvestigation {
  value?: string
}

export interface AddToListInvestigationEvaluation
  extends InvestigationEvaluation {
  evaluatedInvestigations: Array<AddToListEvaluatedInvestigation>
}

interface GetAddToListEvaluatedRows {
  selectedRows: Array<Key>
  setSelectedRows: (rows: Array<Key>) => void
  evaluation: AddToListInvestigationEvaluation
}

const useAddToListEvaluatedData = (
  listType: PredictionListTypeAddToList,
  dataSource?: DataSource
): GetAddToListEvaluatedRows => {
  const { selectedInvestigations } = useBulkActionsContext()

  const [selectedRows, setSelectedRows] = useState<Array<Key>>(
    selectedInvestigations.map(({ investigationId }) => investigationId)
  )

  const [evaluation, setEvaluation] =
    useState<AddToListInvestigationEvaluation>({
      evaluatedInvestigations: [],
      selectedAndValidIds: [],
    })

  useEffect(() => {
    const evaluation = getAddToListInvestigationEvaluation(
      selectedInvestigations,
      listType,
      dataSource
    )

    setEvaluation(evaluation)
    setSelectedRows(evaluation.selectedAndValidIds)
  }, [dataSource, listType, selectedInvestigations])

  return {
    selectedRows,
    setSelectedRows,
    evaluation,
  }
}

export default useAddToListEvaluatedData
