import { FC, ReactNode } from 'react'
import { Col, Row } from 'antd'
import { Space, IconProps } from '@signifyd/components'
import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import cx from 'classnames'
import styles from './GridSelect.less'

export interface RadioButtonItem {
  icon: FC<IconProps>
  key: PREDICTION_LIST_TYPE
  label: ReactNode
}

export interface Props {
  centered?: boolean
  items: Array<RadioButtonItem>
  span: 8 | 12
  value?: PREDICTION_LIST_TYPE
  onChange: (value: PREDICTION_LIST_TYPE) => void
}

const GRID_GUTTER: [number, number] = [16, 16]

const GridSelect: FC<Props> = ({ centered, items, value, span, onChange }) => {
  return (
    <div className={cx(styles.wrapper, { [styles.centered]: centered })}>
      <Row gutter={GRID_GUTTER} className={styles[`rowSpan${span}`]}>
        {items.map(({ icon: Icon, key, label }) => (
          <Col key={key} span={span} className={styles.col}>
            <div
              className={cx(styles.item, { [styles.active]: key === value })}
              onClick={() => onChange(key)}
            >
              <Icon className={styles.icon} />
              <Space size="xs" />
              <p className={styles.label}>{label}</p>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default GridSelect
