import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, Space } from '@signifyd/components'
import ReadMoreButton from '../../ReadMoreButton'
import styles from './BulkActionsNotificationDescription.less'

export type NotificationType = 'success' | 'error' | 'open'

interface Props {
  type: NotificationType
  total: number
  subset?: number
  onClick?: () => void
}

const BulkActionsNotificationDescription: FC<Props> = ({
  type,
  total,
  subset,
  onClick,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Space size={12} />
      <Text>
        {t(`bulkActions.notification.description.${type}`, {
          count: subset,
        })}
      </Text>
      {type !== 'open' && (
        <>
          <Space size={2} />
          <Text className={styles.note}>
            {subset === total
              ? t(`bulkActions.notification.descriptionTotal.all${type}`)
              : t(`bulkActions.notification.descriptionTotal.${type}`, {
                  subset,
                  count: total,
                })}
          </Text>
          <Space size={2} />
          {type === 'error' && <ReadMoreButton onClick={onClick} />}
        </>
      )}
    </>
  )
}

export default BulkActionsNotificationDescription
