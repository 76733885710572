import { FC } from 'react'
import { useBulkActionsContext } from '../BulkActionsProvider'
import BulkActionsNotesModal from './BulkActionsNotesModal'
import BulkActionsNotesModalContent from './BulkActionsNotesModalContent'

export const MAX_NOTE_LENGTH = 1000

const CoreBulkActionsNotesModal: FC = () => {
  const { setNote, setBulkActionType, setBulkActionStage } =
    useBulkActionsContext()

  const handleCancel = (): void => {
    setNote('')
    setBulkActionType(undefined)
  }

  const handleOk = (): void => {
    setBulkActionStage('CONFIRMATION')
  }

  return (
    <BulkActionsNotesModal
      onNext={handleOk}
      onPrevious={handleCancel}
      isVisible
    >
      <BulkActionsNotesModalContent />
    </BulkActionsNotesModal>
  )
}

export default CoreBulkActionsNotesModal
