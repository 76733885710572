// Temp fix for admin download all fields until powersearch table customisation project is deployed
export const ADMIN_FIELDS = [
  'investigationId',
  'createdAt',
  'guaranteeDisposition',
  'recommendedDecisionDisposition',
  'claimStatus',
  'investigationReviewDisposition',
  'returnPolicyAction',
  'cardHolderName',
  'recipientFullName',
  'userAccountEmail',
  'recipientConfirmationEmail',
  'billingAddress',
  'recipientAddress',
  'ipGeolocationFull',
  'userAccountPhone',
  'recipientConfirmationPhone',
  'shopperName',
  'items',
  'discountCode',
  'orderTotalAmount',
  'shippingMethod',
  'cardBinCountryCode',
  'billingAddressProvinceCode',
  'avsResponseCode',
  'cvvResponseCode',
  'cardBin',
  'cardLastFourDigits',
  'deviceId',
  'browserIp',
  'authorizationFailureReason',
  'authorizationGatewayStatus',
  'paymentGateway',
  'orderChannel',
  'teamName',
  'signifydScore',
  'orderId',
  'userAccountNumber',
  'isTestInvestigation',
  'accountHolderTaxId',
  'sellerId',
  'sellerParentEntity',
  'sellerName',
  'caseStatus',
  'caseType',
  'checkoutPolicyAction',
  'guaranteeSubmittedAt',
  'guaranteeReviewedAt',
  'caseUrl',
  'checkoutPolicyName',
  'returnPolicyName',
  'recordLocator',
  'leadPassengerName',
]
