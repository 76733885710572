import { getUserBulkActionsPermission } from '@signifyd/http'
import { useQuery, UseQueryResult } from '@tanstack/react-query'

const useGetUserBulkActionsPermission = (): UseQueryResult<boolean> => {
  return useQuery(['bulkpermissions'], async () => {
    const {
      data: { canPerformBulkAction },
    } = await getUserBulkActionsPermission()

    return canPerformBulkAction
  })
}

export default useGetUserBulkActionsPermission
