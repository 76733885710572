export const listItems = {
  physicalGoods: [
    'cardholderName',
    'externalOrderId',
    'caseId',
    'orderTotalAmount',
    'platformOrderId',
    'recipientFullName',
    'recipientConfirmationEmailAddress',
    'recipientConfirmationPhone',
    'transactionId',
    'userAccountEmailAddress',
    'userAccountPhoneNumber',
    'userAccountUsername',
    'checkoutToken',
    'sellerId',
    'parentEntity',
    'sellerName',
  ],
  airlines: [
    'cardholderName',
    'passengerNameRecord',
    'leadPassenger',
    'externalOrderId',
    'caseId',
    'orderTotalAmount',
    'platformOrderId',
    'recipientFullName',
    'recipientConfirmationEmailAddress',
    'recipientConfirmationPhone',
    'transactionId',
    'userAccountEmailAddress',
    'userAccountPhoneNumber',
    'userAccountUsername',
    'checkoutToken',
  ],
  admin: [
    'cardholderName',
    'passengerNameRecord',
    'leadPassenger',
    'externalOrderId',
    'caseId',
    'orderTotalAmount',
    'platformOrderId',
    'recipientFullName',
    'recipientConfirmationEmailAddress',
    'recipientConfirmationPhone',
    'transactionId',
    'userAccountEmailAddress',
    'userAccountPhoneNumber',
    'userAccountUsername',
    'checkoutToken',
    'sellerId',
    'parentEntity',
    'sellerName',
  ],
}
