import { FC } from 'react'
import {
  arrayToCommaDelimitedString,
  commaDelimitedStringToArray,
} from '@signifyd/utils'
import {
  COL_FILTER_TYPES,
  COL_FILTERS,
  ColFilterKey,
  FilterStateValues,
  StringRangeFilter,
} from 'pages/InvestigationSearchPage/containers/SearchResultsTable/ColumnFilterConfig'
import TextInput from '../TextInput'
import TextInputRange from '../TextInputRange'
import MultiSelect from '../MultiSelect'

export interface FilterInputProps {
  size?: 'medium' | 'large'
  columnKey: ColFilterKey
  internalFilterValue: FilterStateValues
  submitDisabled?: boolean
  onFilterValueChange: (updatedValue: FilterStateValues) => void
  onSubmit: () => void
}

const FilterInput: FC<FilterInputProps> = ({
  internalFilterValue,
  onFilterValueChange,
  onSubmit,
  columnKey,
}) => {
  const filterType = COL_FILTERS[columnKey].TYPE

  switch (filterType) {
    case COL_FILTER_TYPES.TEXT:
      return (
        <TextInput
          value={internalFilterValue as string}
          onChange={onFilterValueChange}
          onEnterPress={onSubmit}
          columnKey={columnKey}
          type="text"
        />
      )
    case COL_FILTER_TYPES.NUMBER:
      return (
        <TextInput
          value={internalFilterValue as string}
          onChange={onFilterValueChange}
          onEnterPress={onSubmit}
          columnKey={columnKey}
          type="number"
        />
      )
    case COL_FILTER_TYPES.NUMBER_WITH_RANGE:
      return (
        <TextInputRange
          value={internalFilterValue as StringRangeFilter}
          onChange={onFilterValueChange}
          columnKey={columnKey}
        />
      )
    case COL_FILTER_TYPES.MULTI_SELECT:
      return (
        <MultiSelect
          value={commaDelimitedStringToArray(internalFilterValue as string)}
          onChange={(values: Array<string>) => {
            onFilterValueChange(arrayToCommaDelimitedString(values))
          }}
          columnKey={columnKey}
        />
      )
    default:
      return null
  }
}

export default FilterInput
