import {
  useQueryParams,
  StringParam,
  NumberParam,
  DecodedValueMap,
} from 'use-query-params'
import { INV_SIMILARITY_FIELD } from '@signifyd/http'

export type SimilarityParamsResult = DecodedValueMap<typeof similarityParamsMap>

export interface SimilarityParams {
  similarityAttribute: INV_SIMILARITY_FIELD
  similarityInvestigationId: number
}

export interface SimilarityHook extends SimilarityParams {
  resetSimilarityParams: () => void
}

const similarityParamsMap = {
  similarityInvestigationId: NumberParam,
  similarityAttributes: StringParam,
}

/* Sim params get passed in as SIM_IP_ADDRESS but is used everywhere as IP_ADDRESS */
const formatSimilarityField = (attribute: string): string => {
  return attribute.replace('SIM_', '')
}

const isValidSimilarityAttribute = (
  similarityQueryParam: string
): similarityQueryParam is INV_SIMILARITY_FIELD =>
  similarityQueryParam in INV_SIMILARITY_FIELD

/** These are params passed by the orders console when a use clicks a link currently in SummaryPanelActions */
const useSimilarityParams = (): SimilarityHook | null => {
  const [query, setQuery] = useQueryParams(similarityParamsMap)
  const { similarityAttributes, similarityInvestigationId } = query

  if (!similarityAttributes || !similarityInvestigationId) {
    return null
  }

  const formattedAttribute = formatSimilarityField(similarityAttributes)

  if (!isValidSimilarityAttribute(formattedAttribute)) {
    return null
  }

  const resetSimilarityParams = (): void => {
    setQuery({
      similarityAttributes: null,
      similarityInvestigationId: null,
    })
  }

  return {
    similarityAttribute: INV_SIMILARITY_FIELD[formattedAttribute],
    similarityInvestigationId: +similarityInvestigationId,
    resetSimilarityParams,
  }
}

export default useSimilarityParams
