import { notification } from 'antd'
import { i18nInstance } from '@signifyd/components'
import BulkActionsNotificationDescription, {
  NotificationType,
} from 'pages/InvestigationSearchPage/components/BulkActions/BulkActionsNotificationDescription'
import { BulkActionType } from 'pages/InvestigationSearchPage/components/BulkActions/types'
import { BulkActionEvaluatedResponse } from './usePerformBulkActions'

const openNotification = (
  type: NotificationType,
  total: number,
  subset?: number,
  onClick?: () => void
): void => {
  notification[type]({
    message: i18nInstance.t(`bulkActions.notification.message.${type}`),
    description: (
      <BulkActionsNotificationDescription
        type={type}
        total={total}
        subset={subset}
        onClick={onClick}
      />
    ),
    duration: 0,
    key: `bulkActionsNotification-${type}`,
  })
}

export const handleBulkActionNotifications = (
  bulkActionType: BulkActionType,
  bulkActionResponse: BulkActionEvaluatedResponse,
  onClick?: () => void
): void => {
  const { allOutcomes, successOutcomes, failedOutcomes } = bulkActionResponse

  if (bulkActionType === 'SUBMIT_FOR_GUARANTEE') {
    openNotification('open', allOutcomes.length, undefined, onClick)

    return
  }

  if (successOutcomes.length) {
    openNotification(
      'success',
      allOutcomes.length,
      successOutcomes.length,
      onClick
    )
  }

  if (failedOutcomes.length) {
    openNotification(
      'error',
      allOutcomes.length,
      failedOutcomes.length,
      onClick
    )
  }
}
