import { FC, useState } from 'react'
import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { ButtonProps } from 'antd/lib/button'
import { useBulkActionsContext } from '../../BulkActionsProvider'
import SelectListTypeModalContent from './SelectListTypeModalContent'
import { MODAL_WIDTH } from '../../constants'
import { useGetBulkActionPredictionLists } from '../../queries/useGetBulkActionsPredictionLists'

interface Props {
  onNext: (listType: PREDICTION_LIST_TYPE) => void
  onPrevious: () => void
}

const SelectListTypeModal: FC<Props> = ({ onNext, onPrevious }) => {
  const [selectedListType, setSelectedListType] =
    useState<PREDICTION_LIST_TYPE>()

  const { t } = useTranslation()
  const { bulkActionStage } = useBulkActionsContext()

  const handleListTypeChange = (listType: PREDICTION_LIST_TYPE): void => {
    setSelectedListType(listType)
  }

  const handleOk = (): void => {
    if (selectedListType) {
      onNext(selectedListType)
    }
  }

  const { data: predictionListData } = useGetBulkActionPredictionLists()

  const listsMap = predictionListData?.lists

  if (!listsMap) {
    return null
  }

  const lists = Object.keys(listsMap).filter(
    (key) => !!listsMap[key as PREDICTION_LIST_TYPE]?.length
  ) as Array<PREDICTION_LIST_TYPE>

  return (
    <Modal
      okType="link"
      cancelButtonProps={
        {
          'data-analytics-id': 'bulk-actions-select-list-type-cancel',
          type: 'link',
        } as ButtonProps
      }
      maskClosable={false}
      closable
      destroyOnClose={false}
      width={MODAL_WIDTH}
      open={bulkActionStage === 'SELECT_LIST_TYPE'}
      onCancel={onPrevious}
      onOk={handleOk}
      okText={t('bulkActions.bulkAddToList.addToListModal.continueButton')}
      okButtonProps={
        {
          'data-analytics-id': 'bulk-actions-select-list-type-ok',
          type: 'link',
          disabled: !selectedListType,
        } as ButtonProps
      }
      title={
        <Text block weight="semibold">
          {t('bulkActions.bulkAddToList.addToListModal.title')}
        </Text>
      }
    >
      <SelectListTypeModalContent
        listTypes={lists}
        onChange={handleListTypeChange}
        selectedListType={selectedListType}
      />
    </Modal>
  )
}

export default SelectListTypeModal
