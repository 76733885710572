import investigationSearch from './investigationSearch'
import columnFilters from './columnFilters'
import searchBar from './searchBar'
import dynamicFilters from './dynamicFilters'
import similarity from './similarity'
import icon from './icon'
import bulkActions from './bulkActions'
import columnDrawer from './columnDrawer'

export default {
  investigationSearch,
  columnFilters,
  searchBar,
  dynamicFilters,
  similarity,
  icon,
  bulkActions,
  columnDrawer,
}
