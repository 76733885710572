import { FC, ReactElement } from 'react'
import cx from 'classnames'
import styles from './SearchResultsCellLabel.less'

type LabelType =
  | 'g-disposition'
  | 'chargeback'
  | 'score'
  | 'review'
  | 'code'
  | 'test'

interface Props {
  type: LabelType
  cssClass: string
  label: string | ReactElement
}

const SearchResultsCellLabel: FC<Props> = ({ type, cssClass, label }) => {
  const cssClassType = `${type}-${cssClass}`
  const typeStyles =
    cssClassType in styles ? styles[cssClassType as keyof typeof styles] : ''

  return <div className={cx([styles.cellLabel, typeStyles])}>{label}</div>
}

export default SearchResultsCellLabel
