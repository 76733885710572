import {
  DelimitedNumericArrayParam,
  decodeDelimitedNumericArray,
  useQueryParams,
} from 'use-query-params'

interface UseTeamCustomerIdQueryParams {
  teamIds: Array<number>
  customerIds: Array<number>
}

const DelimitedNumericArrayWithoutNullsParam = {
  ...DelimitedNumericArrayParam,
  decode: (arrayStr: Array<string | null> | string | null | undefined) => {
    const decoded = decodeDelimitedNumericArray(arrayStr) ?? []

    return decoded.filter((item): item is number => !!item)
  },
}

export const useTeamCustomerIdQueryParams =
  (): UseTeamCustomerIdQueryParams => {
    const [query] = useQueryParams({
      customerId: DelimitedNumericArrayWithoutNullsParam,
      teamId: DelimitedNumericArrayWithoutNullsParam,
    })

    return { teamIds: query.teamId, customerIds: query.customerId }
  }
