import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Space, Text, NoteTextArea } from '@signifyd/components'
import { MAX_NOTE_LENGTH } from './CoreBulkActionsNotesModal'
import { useBulkActionsContext } from '../BulkActionsProvider'

const BulkActionsNotesModalContent: FC = () => {
  const { t } = useTranslation()

  const {
    note,
    setNote,
    bulkActionType,
    selectedInvestigations,
    fileAttacher,
  } = useBulkActionsContext()

  if (!bulkActionType) {
    return null
  }

  return (
    <>
      <Text data-test-id="bulkActionsNotesModalTitle">
        <Trans
          i18nKey={`bulkActions.notesModal.actionButtons.${bulkActionType}`}
          values={{ count: selectedInvestigations.length }}
          components={{ bold: <Text weight="semibold" /> }}
        />
      </Text>
      <Space size={12} />

      <NoteTextArea
        note={note}
        maxLength={MAX_NOTE_LENGTH}
        fileAttacher={fileAttacher}
        onChange={(value) => setNote(value)}
        placeholder={t('bulkActions.notesModal.addNotesPlaceholder')}
        textAreaAnalyticsId="bulk-actions-notes-modal-text-area"
        attachFileButtonAnalyticsId="bulk-actions-attach-file-button"
      />
    </>
  )
}

export default BulkActionsNotesModalContent
