import { BulkActionOutcome, InvestigationInfo } from '@signifyd/http'
import { FC, ReactNode, createContext, useContext, useState } from 'react'
import { AttachFilesForUploadHook, useFileAttacher } from '@signifyd/components'
import { Key } from 'antd/lib/table/interface'
import { BulkActionType, BulkActionStage } from './types'

export interface BulkActionsContext {
  bulkActionType?: BulkActionType
  setBulkActionType: (type?: BulkActionType) => void
  bulkActionStage?: BulkActionStage
  setBulkActionStage: (stage: BulkActionStage) => void
  failedOutcomes: Array<BulkActionOutcome>
  setFailedOutcomes: (failedOutcomes: Array<BulkActionOutcome>) => void
  note: string
  setNote: (note: string) => void
  onSelectChange: (keys: Array<string>, data: Array<InvestigationInfo>) => void
  allInvestigations: Array<InvestigationInfo>
  selectedInvestigations: Array<InvestigationInfo>
  onReset: () => void
  fileAttacher: AttachFilesForUploadHook
  resetAddToList: () => void
}

export const bulkActionsContext = createContext<BulkActionsContext>(
  {} as BulkActionsContext
)

export const useBulkActionsContext = (): BulkActionsContext => {
  return useContext(bulkActionsContext)
}

export interface BulkActionsProviderDefaultValues {
  note?: string
  bulkActionType?: BulkActionType
  bulkActionStage?: BulkActionStage
  failedOutcomes?: Array<BulkActionOutcome>
}

export interface BulkActionsProviderProps {
  children: ReactNode
  onSelectChange: (keys: Array<Key>, data: Array<InvestigationInfo>) => void
  selectedInvestigations: Array<InvestigationInfo>
  allInvestigations: Array<InvestigationInfo>
  defaultValues?: BulkActionsProviderDefaultValues
}

export const BulkActionsProvider: FC<BulkActionsProviderProps> = ({
  children,
  onSelectChange,
  selectedInvestigations,
  allInvestigations,
  defaultValues,
}) => {
  const fileAttacher = useFileAttacher()

  const [bulkActionType, setBulkActionType] = useState<
    BulkActionType | undefined
  >(defaultValues?.bulkActionType)
  const [bulkActionStage, setBulkActionStage] = useState<
    BulkActionStage | undefined
  >(defaultValues?.bulkActionStage)

  const [failedOutcomes, setFailedOutcomes] = useState<
    Array<BulkActionOutcome>
  >(defaultValues?.failedOutcomes ?? [])

  const [note, setNote] = useState(defaultValues?.note ?? '')

  const handleReset = (): void => {
    setNote('')
    fileAttacher.clearFiles()
    setBulkActionType(undefined)
    onSelectChange([], [])
  }

  const resetAddToList = (): void => {
    setNote('')
    setBulkActionType(undefined)
  }

  return (
    <bulkActionsContext.Provider
      value={{
        bulkActionType,
        setBulkActionType,
        bulkActionStage,
        setBulkActionStage,
        failedOutcomes,
        setFailedOutcomes,
        note,
        setNote,
        onSelectChange,
        selectedInvestigations,
        onReset: handleReset,
        fileAttacher,
        allInvestigations,
        resetAddToList,
      }}
    >
      {children}
    </bulkActionsContext.Provider>
  )
}
