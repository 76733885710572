import { useQueryParams, SetQuery } from 'use-query-params'
import {
  getSearchQueryParams,
  DecodedSearchParams,
  SearchParamsMap,
  getDefaultSearchRange,
} from '@signifyd/utils'

interface UseTableQueryParams {
  query: DecodedSearchParams
  setQuery: SetQuery<SearchParamsMap>
  resetTableFilters: () => void
}

export const useTableQueryParams = (): UseTableQueryParams => {
  const [query, setQuery] = useQueryParams(getSearchQueryParams())

  const resetTableFilters = (): void => {
    const clearedFilters: Partial<DecodedSearchParams> = {}

    Object.keys(query).forEach((key) => {
      clearedFilters[key as keyof DecodedSearchParams] = undefined
    })

    setQuery({
      ...clearedFilters,
      normalizedPurchaseCreatedAt: getDefaultSearchRange(),
    })
  }

  return { query, setQuery, resetTableFilters }
}
