import { FC } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import styles from './CloseButton.less'
import { useBulkActionsContext } from '../../BulkActionsProvider'

const CloseButton: FC<ButtonProps> = ({ ...rest }) => {
  const { onReset } = useBulkActionsContext()

  return (
    <Button
      {...rest}
      className={styles.closeButton}
      onClick={onReset}
      data-analytics-id="bulk-actions-close-button"
      data-test-id="closeButton"
    >
      <CloseOutlined />
    </Button>
  )
}

export default CloseButton
