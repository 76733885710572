export default {
  button: 'Columns',
  hiddenColumnsButton: 'Hidden columns ({{count}})',
  title: 'Columns',
  reset: 'Reset to default',
  cancel: 'Cancel',
  apply: 'Apply',
  visibleColumns: 'Visible columns',
  hiddenColumns: 'Hidden columns',
  confirmChanges: {
    keep: 'Keep',
    cancel: 'Cancel changes',
    title: 'Cancel changes to columns?',
    subtext: 'Any changes will be lost.',
  },
  defaultColumns: {
    caseId: 'Case ID',
    date: 'Date',
    guaranteeDisposition: 'Guarantee disposition',
  },
  empty: {
    header: 'Hidden columns will appear here',
    value:
      'Use the icon beside each column name above to hide columns from the table',
  },
  showColumn: 'Show',
  hideColumn: 'Hide',
  savedColumns: {
    error: 'Error retrieving saved column preferences. Please try again',
  },
  hiddenColumnsPopconfirm: {
    title: 'Search results not fully shown',
    subtext: 'Some search results found in hidden columns.',
  },
  seeColumns: 'See columns',
  skip: 'Skip',
}
