import { Key } from 'antd/es/table/interface'
import {
  createContext,
  FC,
  RefObject,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react'

export interface TableContext {
  tableContainer: RefObject<HTMLDivElement>
  saveCurrentColumnPosition: () => void
  scrollToSavedPosition: () => void
  resetTableScroll: () => void
  rerenderTable: () => void
  tableRenderKey: number
  selectedRowKeys: Array<Key>
  setSelectedRowKeys: (selectedRowKeys: Array<Key>) => void
}

const ANT_TABLE_BODY_CLASS_NAME = '.ant-table-body'

export const tableContext = createContext<TableContext>({} as TableContext)

export const useTableContext = (): TableContext => {
  return useContext(tableContext)
}

export const TableProvider: FC = ({ children }) => {
  const tableContainer = useRef<HTMLDivElement>(null)

  const [tableRenderKey, setTableRenderKey] = useState(Date.now())
  const [scrollX, setScrollX] = useState(0)

  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<Key>>([])

  const rerenderTable = (): void => setTableRenderKey(Date.now())

  const saveCurrentColumnPosition = (): void => {
    if (tableContainer.current) {
      const antTableBody = tableContainer.current.querySelector(
        ANT_TABLE_BODY_CLASS_NAME
      )

      const scrollX = antTableBody?.scrollLeft ?? 0

      setScrollX(scrollX)
    }
  }

  const scrollToSavedPosition: () => void = useCallback((): void => {
    const antTableBody = tableContainer.current?.querySelector(
      ANT_TABLE_BODY_CLASS_NAME
    )

    if (antTableBody) {
      antTableBody.scrollTo(scrollX, 0)
    }
  }, [scrollX])

  const resetTableScroll = (): void => {
    const antTableBody = tableContainer.current?.querySelector(
      ANT_TABLE_BODY_CLASS_NAME
    )

    setScrollX(0)

    if (antTableBody) {
      antTableBody.scrollTo(scrollX, 0)
    }
  }

  return (
    <tableContext.Provider
      value={{
        tableContainer,
        saveCurrentColumnPosition,
        scrollToSavedPosition,
        resetTableScroll,
        rerenderTable,
        tableRenderKey,
        selectedRowKeys,
        setSelectedRowKeys: useCallback(
          (selectedRowKeys: Array<Key>) => setSelectedRowKeys(selectedRowKeys),
          [setSelectedRowKeys]
        ),
      }}
    >
      {children}
    </tableContext.Provider>
  )
}
