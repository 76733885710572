import {
  useQueryParams,
  NumberParam,
  DecodedValueMap,
  SetQuery,
  withDefault,
} from 'use-query-params'

const DEFAULT_PAGE = 1

const DEFAULT_PAGE_SIZE = 50

const paginationQueryMap = {
  current: withDefault(NumberParam, DEFAULT_PAGE),
  pageSize: withDefault(NumberParam, DEFAULT_PAGE_SIZE),
}

export type PaginationQueryResult = DecodedValueMap<typeof paginationQueryMap>

export interface UseTablePaginationHook {
  query: DecodedValueMap<typeof paginationQueryMap>
  setPagination: SetQuery<typeof paginationQueryMap>
  resetPagination: () => void
}

export const useTablePagination = (): UseTablePaginationHook => {
  const [query, setQuery] = useQueryParams(paginationQueryMap)

  const resetPagination = (): void => {
    setQuery({
      current: DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
    })
  }

  return { query, setPagination: setQuery, resetPagination }
}
