export default {
  resetButton: 'Reset',
  applyButton: 'Apply',
  inputs: {
    textInput: {
      placeholder: 'Search',
    },
    rangeInput: {
      minPlaceholder: 'Min',
      maxPlaceholder: 'Max',
      inputSeparator: 'to',
    },
  },
}
