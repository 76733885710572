import { FC } from 'react'
import { Layout } from '@signifyd/components'
import SearchContainer from 'pages/InvestigationSearchPage/containers/SearchContainer'
import { UserData } from 'core/hooks/useGetUserData'
import SearchTitleBar from './components/SearchTitleBar'
import styles from './InvestigationSearchPage.less'
import NavigationWrapper from './components/NavigationWrapper/NavigationWrapper'
import SearchBarContainer from './containers/SearchBarContainer'
import { TableProvider } from './containers/SearchContainer/TableProvider'

interface Props {
  userData: UserData
}

const { Content, NavBar, Header } = Layout

const InvestigationSearchPage: FC<Props> = ({ userData }: Props) => {
  return (
    <TableProvider>
      <Layout data-test-id="investigationSearchPage">
        <NavBar>
          <NavigationWrapper userData={userData} />
        </NavBar>
        <Header
          paddingTop="none"
          paddingBottom="none"
          marginBottom="none"
          className={styles.searchHeading}
          innerMaxWidth={null}
        >
          <SearchTitleBar userData={userData} />
        </Header>
        <Content innerStyle={{ height: '100%' }} innerMaxWidth={null}>
          <SearchBarContainer userData={userData} />
          <SearchContainer />
        </Content>
      </Layout>
    </TableProvider>
  )
}

export default InvestigationSearchPage
