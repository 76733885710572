import { CORE_BULK_ACTION_TYPE } from '@signifyd/http'

export type BulkActionType = 'ADD_TO_LIST' | CORE_BULK_ACTION_TYPE

export type CoreFlowStage = 'NOTES' | 'READ_MORE' | 'CONFIRMATION'

export type AddToListStage =
  | 'SELECT_LIST_TYPE'
  | 'SELECT_VALUE_DATASOURCE'
  | 'SELECT_LIST'
  | 'NOTES'
  | 'CONFIRMATION'
  | 'HANDLE_DUPLICATES'
  | 'READ_MORE'

export type BulkActionStage = CoreFlowStage | AddToListStage

export enum EMAIL_TYPE {
  CONFIRMATION_EMAIL = 'CONFIRMATION_EMAIL',
  ACCOUNT_EMAIL = 'ACCOUNT_EMAIL',
}

export enum ADDRESS_TYPE {
  DELIVERY_ADDRESS = 'DELIVERY_ADDRESS',
  BILLING_ADDRESS = 'BILLING_ADDRESS',
}

export type DataSource = EMAIL_TYPE | ADDRESS_TYPE
