import { FC, ReactNode } from 'react'
import { Popover, Button } from 'antd'
import { ExternalLink } from '@signifyd/components'
import { SEARCH_FIELD } from '@signifyd/http'
import styles from './MultiItemPopover.less'

interface ItemPopoverProps {
  items: Array<string | null>
  title: string
  isLinked?: boolean
  searchPrefix?: SEARCH_FIELD
}

const renderValue = (
  value: string,
  isLinked: boolean,
  searchPrefix?: SEARCH_FIELD
): ReactNode => {
  if (isLinked && searchPrefix) {
    return (
      <ExternalLink url={`/casesearch?${searchPrefix}=${value}`}>
        {value}
      </ExternalLink>
    )
  }

  return value
}

const MultiItemPopover: FC<ItemPopoverProps> = ({
  items,
  title,
  isLinked = false,
  searchPrefix,
}) => {
  const [firstItemValue] = items

  if (!firstItemValue) {
    return null
  }

  const uniqueItemValues = [...(new Set(items.filter(Boolean)) as Set<string>)]

  const popoverContent = uniqueItemValues.map((value): ReactNode => {
    return <div key={value}>{renderValue(value, isLinked, searchPrefix)}</div>
  })

  return (
    <>
      {renderValue(firstItemValue, isLinked, searchPrefix)}

      {uniqueItemValues.length > 1 && (
        <span data-test-id="multipleItemPopover">
          <Popover
            placement="topLeft"
            title={title}
            content={popoverContent}
            trigger="hover"
          >
            <Button className={styles.itemPopoverBtn} shape="circle">
              +{uniqueItemValues.length - 1}
            </Button>
          </Popover>
        </span>
      )}
    </>
  )
}

export default MultiItemPopover
